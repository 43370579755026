import {
  ChangeOrderPermissions,
  ChangeOrderPermissionsQuery,
  ChangeOrderPermissionsQueryVariables,
  ChangeOrderStatus,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import uiStorage from 'src/lib/localStorage/uiStorage'

const QUERY = gql`
  query ChangeOrderPermissionsQuery {
    permissions {
      changeOrder {
        readType
        writeType
        readChange
        writeChange
        readMetrics
        approveChangeOrderStage
      }
    }
  }
`

const useChangeOrderPermissions = () => {
  const { loading, data } = useQuery<
    ChangeOrderPermissionsQuery,
    ChangeOrderPermissionsQueryVariables
  >(QUERY)

  React.useEffect(() => {
    if (data) {
      uiStorage.cachedPermissions.set(data.permissions)
    }
  }, [data])

  const cachedPermissions = uiStorage.cachedPermissions.get()

  return {
    loading,
    changeOrderPermissions: cachedPermissions?.changeOrder ?? null,
  }
}

type MaybeChangeOrderPermissions = ChangeOrderPermissions | null

export const useChangeOrderActions = () => {
  const { changeOrderPermissions } = useChangeOrderPermissions()
  return {
    canManageChangeOrderTypes: () =>
      canManageChangeOrderTypes(changeOrderPermissions),
    canSeeChangeOrderMetrics: () =>
      canSeeChangeOrderMetrics(changeOrderPermissions),
    canSeeChangeOrders: () => canSeeChangeOrders(changeOrderPermissions),
    canReopenChangeOrder: (status: ChangeOrderStatus) =>
      canReopenChangeOrder(status, changeOrderPermissions),
  }
}

const canReopenChangeOrder = (
  status: ChangeOrderStatus,
  changeOrderPermissions: MaybeChangeOrderPermissions
): boolean => {
  if (!changeOrderPermissions?.writeChange) {
    return false
  }

  if (status === 'CANCELLED') {
    return true
  }

  return false
}

const canManageChangeOrderTypes = (
  changeOrderPermissions: MaybeChangeOrderPermissions
): boolean => {
  if (!changeOrderPermissions?.writeType) {
    return false
  }
  return true
}

const canSeeChangeOrderMetrics = (
  changeOrderPermissions: MaybeChangeOrderPermissions
): boolean => {
  if (!changeOrderPermissions?.readMetrics) {
    return false
  }
  return true
}

const canSeeChangeOrders = (
  changeOrderPermissions: MaybeChangeOrderPermissions
): boolean => {
  if (
    !changeOrderPermissions?.readType &&
    !changeOrderPermissions?.readChange
  ) {
    return false
  }
  return true
}
