import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import useNavStore from 'src/stores/useNavStore'

import { IconChevronLeft, IconChevronRight } from '../Icon'

const DRAWER_TOGGLE_WIDTH = 24

const DrawerToggleButton = () => {
  const { palette, customShadows } = useTheme()
  const { open, navWidth, handleDrawerToggle } = useNavStore()

  return (
    <Box
      width={DRAWER_TOGGLE_WIDTH}
      height={DRAWER_TOGGLE_WIDTH}
      sx={({ transitions }) => ({
        position: 'fixed',
        top: 24,
        left: navWidth - DRAWER_TOGGLE_WIDTH / 2,
        cursor: 'pointer',
        textAlign: 'center',
        border: `1px solid ${palette.grey.border}`,
        boxShadow: customShadows[0],
        borderRadius: '32px',
        backgroundColor: palette.background.paper,
        zIndex: ({ zIndex }) => zIndex.drawer,
        transition: transitions.create('left', {
          easing: transitions.easing.sharp,
          duration: transitions.duration.standard,
        }),
      })}
      onClick={() => handleDrawerToggle(!open)}
    >
      {open ? (
        <IconChevronLeft style={{ width: 7 }} color={palette.text.primary} />
      ) : (
        <IconChevronRight style={{ width: 7 }} color={palette.text.primary} />
      )}
    </Box>
  )
}

export default DrawerToggleButton
