import useFeature from 'src/hooks/useFeature'

import { handleNotFound } from '../layoutUtils'

type ProductLayoutProps = {
  children: React.ReactNode
}

const ProductLayout = ({ children }: ProductLayoutProps) => {
  const { productsEnabled } = useFeature()
  if (!productsEnabled) return handleNotFound()
  return <>{children}</>
}

export default ProductLayout
