import {
  TrainingAssignmentStatus,
  TrainingPermissions,
  TrainingPermissionsQuery,
  TrainingPermissionsQueryVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import uiStorage from 'src/lib/localStorage/uiStorage'

const QUERY = gql`
  query TrainingPermissionsQuery {
    permissions {
      training {
        readAssignment
        readPolicy
        readOverview
        completeAssignment
        readQuiz
        writeQuiz
        writePolicy
      }
    }
  }
`

const useTrainingPermissions = () => {
  const { loading, data } = useQuery<
    TrainingPermissionsQuery,
    TrainingPermissionsQueryVariables
  >(QUERY)

  React.useEffect(() => {
    if (data) {
      uiStorage.cachedPermissions.set(data.permissions)
    }
  }, [data])

  const cachedPermissions = uiStorage.cachedPermissions.get()

  return {
    loading,
    trainingPermissions: cachedPermissions?.training ?? null,
  }
}

type MaybeTrainingPermissions = TrainingPermissions | null

export const useTrainingActions = () => {
  const { trainingPermissions } = useTrainingPermissions()
  return {
    canSeeTraining: () => canSeeTraining(trainingPermissions),
    canSeeTrainingOverview: () => canSeeTrainingOverview(trainingPermissions),
    canManageTrainingPolicies: () =>
      canManageTrainingPolicies(trainingPermissions),
    canWithdrawTrainingAssignment: (status: TrainingAssignmentStatus) =>
      canWithdrawTrainingAssignment(status, trainingPermissions),
    canCompleteTrainingAssignment: (status: TrainingAssignmentStatus) =>
      canCompleteTrainingAssignment(status, trainingPermissions),
    canCreateOrUpdateTrainingQuiz: () =>
      canCreateOrUpdateTrainingQuiz(trainingPermissions),
  }
}

const canSeeTraining = (
  trainingPermissions: MaybeTrainingPermissions
): boolean => {
  if (
    !trainingPermissions?.readAssignment &&
    !trainingPermissions?.readOverview
  ) {
    return false
  }
  return true
}

const canSeeTrainingOverview = (
  trainingPermissions: MaybeTrainingPermissions
): boolean => {
  if (!trainingPermissions?.readOverview) {
    return false
  }
  return true
}

const canManageTrainingPolicies = (
  trainingPermissions: MaybeTrainingPermissions
): boolean => {
  if (!trainingPermissions?.writePolicy) {
    return false
  }
  return true
}

const canWithdrawTrainingAssignment = (
  status: TrainingAssignmentStatus,
  trainingPermissions: MaybeTrainingPermissions
): boolean => {
  if (!trainingPermissions) {
    return false
  }

  return ['PENDING', 'OVERDUE'].includes(status)
}

const canCompleteTrainingAssignment = (
  status: TrainingAssignmentStatus,
  trainingPermissions: MaybeTrainingPermissions
): boolean => {
  if (!trainingPermissions) {
    return false
  }

  return ['PENDING', 'OVERDUE'].includes(status)
}

const canCreateOrUpdateTrainingQuiz = (
  trainingPermissions: MaybeTrainingPermissions
): boolean => {
  if (!trainingPermissions?.writeQuiz) {
    return false
  }

  return true
}
