import { Chip } from '@mui/material'
import { Role } from 'types/graphql'

import { getRoleString } from 'src/utils'

const UserRoleChip: React.FC<{ role: Role }> = ({ role }) => {
  return <Chip size="small" variant="rounded" label={getRoleString(role)} />
}

export default UserRoleChip
