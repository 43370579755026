import type { SVGProps } from 'react'

export default (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0004 22C16.7317 22 20.5672 17.5228 20.5672 12C20.5672 6.47715 16.7317 2 12.0004 2C7.26903 2 3.43352 6.47715 3.43352 12C3.43352 14.0858 3.98057 16.0224 4.91631 17.625M19.834 7.94595H20.3927C21.8327 7.94595 23 9.07532 23 10.4685V13.5315C23 14.9247 21.8327 16.0541 20.3927 16.0541H19.834M4.16601 7.94595H3.6073C2.16733 7.94595 1 9.07532 1 10.4685V13.5315C1 14.9247 2.16733 16.0541 3.6073 16.0541H4.16601"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0492 10.3561C9.45829 10.921 8.8446 11.3442 8.24675 11.6739C7.99848 11.8108 7.99848 12.1893 8.24675 12.3262C8.8446 12.6559 9.45829 13.079 10.0492 13.6439C10.7364 14.301 11.2675 15.081 11.6911 15.8503C11.8237 16.0911 12.1761 16.0911 12.3087 15.8503C12.7323 15.081 13.2635 14.301 13.9507 13.6439C14.5416 13.079 15.1553 12.6559 15.7531 12.3262C16.0014 12.1893 16.0014 11.8108 15.7531 11.6739C15.1553 11.3442 14.5416 10.921 13.9507 10.3561C13.2635 9.69905 12.7323 8.91908 12.3087 8.14976C12.1761 7.90898 11.8237 7.90898 11.6911 8.14976C11.2675 8.91908 10.7364 9.69905 10.0492 10.3561Z"
      stroke="#E99A00"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
