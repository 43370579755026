import { useMemo } from 'react'

import { LatestProduct } from 'types/graphql'

import { useParams } from '@redwoodjs/router'

import { useProductStore } from 'src/stores'

// Using named export instead of default export because Jest doesn't like
// mocking default export
export const useCurrentProduct = () => {
  const { productId } = useParams()
  const { products } = useProductStore()
  const currentProduct = useMemo<LatestProduct | null>(
    () => products?.[Number(productId)] ?? null,
    [productId, products]
  )

  if (!currentProduct) {
    throw new Error('Product not found')
  }

  return currentProduct
}
