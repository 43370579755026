import {
  QualityEventPermissions,
  QualityEventPermissionsQuery,
  QualityEventPermissionsQueryVariables,
  QualityEventStatus,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import uiStorage from 'src/lib/localStorage/uiStorage'

const QUERY = gql`
  query QualityEventPermissionsQuery {
    permissions {
      qualityEvent {
        readQualityEventType
        writeQualityEventType
        readQualityEvent
        writeQualityEvent
        readMetrics
        approveQualityEventStage
      }
    }
  }
`

const useQualityEventPermissions = () => {
  const { loading, data } = useQuery<
    QualityEventPermissionsQuery,
    QualityEventPermissionsQueryVariables
  >(QUERY)

  React.useEffect(() => {
    if (data) {
      uiStorage.cachedPermissions.set(data.permissions)
    }
  }, [data])

  const cachedPermissions = uiStorage.cachedPermissions.get()

  return {
    loading,
    qualityEventPermissions: cachedPermissions?.qualityEvent ?? null,
  }
}

type MaybeQualityEventPermissions = QualityEventPermissions | null

export const useQualityEventActions = () => {
  const { qualityEventPermissions } = useQualityEventPermissions()
  return {
    canManageQualityEventTypes: () =>
      canManageQualityEventTypes(qualityEventPermissions),
    canSeeQualityEventMetrics: () =>
      canSeeQualityEventMetrics(qualityEventPermissions),
    canSeeQualityEvents: () => canSeeQualityEvents(qualityEventPermissions),
    canReopenQualityEvent: (status: QualityEventStatus) =>
      canReopenQualityEvent(qualityEventPermissions, status),
  }
}

const canReopenQualityEvent = (
  qualityEventPermissions: MaybeQualityEventPermissions,
  status: QualityEventStatus
): boolean => {
  if (!qualityEventPermissions?.writeQualityEvent) {
    return false
  }
  if (status === 'CLOSED' || status === 'CANCELLED') {
    return true
  }
  return false
}

const canManageQualityEventTypes = (
  qualityEventPermissions: MaybeQualityEventPermissions
): boolean => {
  if (!qualityEventPermissions?.writeQualityEventType) {
    return false
  }
  return true
}

const canSeeQualityEventMetrics = (
  qualityEventPermissions: MaybeQualityEventPermissions
): boolean => {
  if (!qualityEventPermissions?.readMetrics) {
    return false
  }
  return true
}

const canSeeQualityEvents = (
  qualityEventPermissions: MaybeQualityEventPermissions
): boolean => {
  if (
    !qualityEventPermissions?.readQualityEventType &&
    !qualityEventPermissions?.readQualityEvent
  ) {
    return false
  }
  return true
}
