export default gql`
  query FindTrainingPoliciesQuery {
    trainingPolicies {
      id
      latestVersion {
        id
        name
        userGroups {
          name
        }
        users {
          id
          initials
          fullName
          status
        }
        documents {
          resourceKey
        }
      }
    }
  }
`
