import { Box } from '@mui/material'

const NavBox: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        '& svg': { width: '16px' },

        '& .MuiListItemButton-root': {
          '& .MuiListItemText-primary': {
            whiteSpace: 'nowrap',
            fontWeight: 'medium',
            color: 'text.secondary',
          },

          '&.Mui-selected': {
            '& .MuiListItemText-primary': {
              color: 'text.primary',
            },
          },
        },
      }}
    >
      {children}
    </Box>
  )
}

export default NavBox
