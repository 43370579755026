const productSummaryQuery = gql`
  query ProductSummaryQuery($productId: Int!) {
    productSummary(id: $productId) {
      designElementCount
      componentCount
      linkedDocumentCount
      deviceLevelDesignElementCount
      deviceLevelDesignElementLockedCount
      designElementUpdatedSinceLastReviewCount
      riskMatrixCreated
      riskCount
      riskMatrixId
    }
  }
`

export default productSummaryQuery
