import {
  SettingsPermissions,
  SettingsPermissionsQuery,
  SettingsPermissionsQueryVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import uiStorage from 'src/lib/localStorage/uiStorage'

const QUERY = gql`
  query SettingsPermissionsQuery {
    permissions {
      settings {
        read
        write
      }
    }
  }
`

const useSettingsPermissions = () => {
  const { loading, data } = useQuery<
    SettingsPermissionsQuery,
    SettingsPermissionsQueryVariables
  >(QUERY)

  React.useEffect(() => {
    if (data) {
      uiStorage.cachedPermissions.set(data.permissions)
    }
  }, [data])

  const cachedPermissions = uiStorage.cachedPermissions.get()

  return {
    loading,
    settingsPermissions: cachedPermissions?.settings ?? null,
  }
}

type MaybeSettingsPermissions = SettingsPermissions | null

export const useSettingsActions = () => {
  const { settingsPermissions } = useSettingsPermissions()
  return {
    canReadSettings: () => canReadSettings(settingsPermissions),
    canChangeSettings: () => canChangeSettings(settingsPermissions),
  }
}

const canReadSettings = (
  settingsPermissions: MaybeSettingsPermissions
): boolean => {
  if (!settingsPermissions?.read) {
    return false
  }
  return true
}

const canChangeSettings = (
  settingsPermissions: MaybeSettingsPermissions
): boolean => {
  if (!settingsPermissions?.read) {
    return false
  }
  return true
}
