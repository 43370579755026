import useFeature from 'src/hooks/useFeature'

import { handleNotFound } from '../layoutUtils'

type TrainingLayoutProps = {
  children?: React.ReactNode
}

const TrainingLayout = ({ children }: TrainingLayoutProps) => {
  const { trainingEnabled } = useFeature()
  if (!trainingEnabled) return handleNotFound()
  return <>{children}</>
}

export default TrainingLayout
