import useFeature from 'src/hooks/useFeature'

import { handleNotFound } from '../layoutUtils'

type PartLayoutProps = {
  children?: React.ReactNode
}

const PartLayout = ({ children }: PartLayoutProps) => {
  const { partsEnabled } = useFeature()
  if (!partsEnabled) return handleNotFound()
  return <>{children}</>
}

export default PartLayout
