import { FC } from 'react'

import { Typography } from '@mui/material'

import { getErrorMessage } from 'src/utils'

const ErrorMessage: FC<{
  error?: Error | string | null
  errorText?: string
  inline?: boolean
}> = ({ error, inline, errorText }) => {
  return (
    <Typography component={inline ? 'span' : 'div'} color="status.error">
      {errorText ? errorText : getErrorMessage(error)}
    </Typography>
  )
}

export default ErrorMessage
