import type {
  CompliancePermissionsQuery,
  CompliancePermissionsQueryVariables,
  CompliancePermissions,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import uiStorage from 'src/lib/localStorage/uiStorage'

const QUERY = gql`
  query CompliancePermissionsQuery {
    permissions {
      compliance {
        read
        write
      }
    }
  }
`

const useCompliancePermissions = () => {
  const { loading, data } = useQuery<
    CompliancePermissionsQuery,
    CompliancePermissionsQueryVariables
  >(QUERY)

  React.useEffect(() => {
    if (data) {
      uiStorage.cachedPermissions.set(data.permissions)
    }
  }, [data])

  const cachedPermissions = uiStorage.cachedPermissions.get()

  return {
    loading,
    compliancePermissions: cachedPermissions?.compliance ?? null,
  }
}

type MaybeCompliancePermissions = CompliancePermissions | null

export const useComplianceActions = () => {
  const { compliancePermissions } = useCompliancePermissions()
  return {
    canSeeCompliance: () => canSeeCompliance(compliancePermissions),
  }
}

const canSeeCompliance = (
  compliancePermissions: MaybeCompliancePermissions
): boolean => {
  if (!compliancePermissions?.read) {
    return false
  }
  return true
}
