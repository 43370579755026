import useFeature from 'src/hooks/useFeature'

import { handleNotFound } from '../layoutUtils'

type SupplierLayoutProps = {
  children?: React.ReactNode
}

const SupplierLayout = ({ children }: SupplierLayoutProps) => {
  const { suppliersEnabled } = useFeature()
  if (!suppliersEnabled) return handleNotFound()
  return <>{children}</>
}

export default SupplierLayout
