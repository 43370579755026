import {
  AuditPermissions,
  AuditPermissionsQuery,
  AuditPermissionsQueryVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import uiStorage from 'src/lib/localStorage/uiStorage'

const QUERY = gql`
  query AuditPermissionsQuery {
    permissions {
      audit {
        read
      }
    }
  }
`

const useAuditPermissions = () => {
  const { loading, data } = useQuery<
    AuditPermissionsQuery,
    AuditPermissionsQueryVariables
  >(QUERY)

  React.useEffect(() => {
    if (data) {
      uiStorage.cachedPermissions.set(data.permissions)
    }
  }, [data])

  const cachedPermissions = uiStorage.cachedPermissions.get()

  return {
    loading,
    auditPermissions: cachedPermissions?.audit ?? null,
  }
}

type MaybeAuditPermissions = AuditPermissions | null

export const useAuditActions = () => {
  const { auditPermissions } = useAuditPermissions()
  return {
    canSeeAuditTrail: () => canSeeAuditTrail(auditPermissions),
  }
}

const canSeeAuditTrail = (auditPermissions: MaybeAuditPermissions): boolean => {
  if (!auditPermissions?.read) {
    return false
  }
  return true
}
