import { ReactNode, useEffect } from 'react'

import { ProductsQuery, ProductsQueryVariables } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { productsQuery } from 'src/graphql/queries'
import { useProductStore } from 'src/stores'

const ProductsContext = ({ children }: { children: ReactNode }) => {
  const { data, error, loading } = useQuery<
    ProductsQuery,
    ProductsQueryVariables
  >(productsQuery)

  const { setLoading, setError, setProducts } = useProductStore()

  useEffect(() => {
    setLoading(loading)
  }, [loading, setLoading])

  useEffect(() => {
    setError(error?.message ?? null)
  }, [error?.message, setError])

  useEffect(() => {
    setProducts(
      // strips out _id which is used by apollo for in memory caching
      data?.products.map(({ _id, ...restOfProduct }) => restOfProduct) ?? []
    )
  }, [data?.products, setProducts])

  return <>{children}</>
}

export default ProductsContext
