import { useFlags } from 'launchdarkly-react-client-sdk'

import { getCurrentOrgUser } from 'src/lib/currentUserUtils'

const useFeature = () => {
  const currentOrgUser = getCurrentOrgUser()

  if (!currentOrgUser) {
    throw new Error('No current org user found!')
  }

  if (!currentOrgUser.organization?.settings) {
    throw new Error('No org settings found')
  }

  const {
    trainingEnabled,
    productsEnabled,
    testAutomationEnabled,
    documentsEnabled,
    suppliersEnabled,
    qualityEventsEnabled,
    partsEnabled,
    complianceEnabled,
    aiEnabled,
    sbomEnabled,
    inventoryEnabled,
    legacyDocWorkflowsEnabled,
  } = currentOrgUser.organization.settings

  const {
    idleTimeoutLogoutAndPeriodicTokenRefetch,
    designControlsScratchpad,
    verValAutomationEnabledFlag,
    customProductDocumentsEnabled,
    miniMrpFlag,
    fmeaFlag,
    liveblocksDesignElementRoomRename,
    designElementDisplayIdResetEnabled,
  } = useFlags()

  return {
    trainingEnabled,
    suppliersEnabled,
    changeOrdersEnabled: documentsEnabled || partsEnabled,
    importExportEnabled: productsEnabled,
    productsEnabled,
    documentsEnabled,
    qualityEventsEnabled,
    idleTimeoutLogoutAndPeriodicTokenRefetch,
    partsEnabled,
    complianceEnabled: complianceEnabled,
    aiEnabled,
    designControlsScratchpad,
    sbomEnabled,
    inventoryEnabled,
    testAutomationEnabled:
      testAutomationEnabled && !!verValAutomationEnabledFlag,
    customProductDocumentsEnabled: !!customProductDocumentsEnabled,
    miniMrpFlag,
    fmeaFlag,
    legacyDocWorkflowsEnabled,
    liveblocksDesignElementRoomRename,
    designElementDisplayIdResetEnabled: !!designElementDisplayIdResetEnabled,
  }
}

export default useFeature
