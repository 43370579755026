import type { SVGProps } from 'react'
const IconUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.0008 15.8369C19.4567 16.5683 20.7049 17.742 21.6161 19.2096C21.7965 19.5003 21.8867 19.6456 21.9179 19.8468C21.9813 20.2558 21.7016 20.7585 21.3207 20.9204C21.1333 21 20.9225 21 20.5008 21M16.0008 11.5322C17.4825 10.7959 18.5008 9.26686 18.5008 7.5C18.5008 5.73314 17.4825 4.20411 16.0008 3.46776M14.0008 7.5C14.0008 9.98528 11.9861 12 9.50081 12C7.01553 12 5.00081 9.98528 5.00081 7.5C5.00081 5.01472 7.01553 3 9.50081 3C11.9861 3 14.0008 5.01472 14.0008 7.5ZM2.56004 18.9383C4.15435 16.5446 6.67019 15 9.50081 15C12.3314 15 14.8473 16.5446 16.4416 18.9383C16.7909 19.4628 16.9655 19.725 16.9454 20.0599C16.9297 20.3207 16.7588 20.64 16.5504 20.7976C16.2827 21 15.9146 21 15.1785 21H3.82317C3.08698 21 2.71889 21 2.45125 20.7976C2.24286 20.64 2.0719 20.3207 2.05625 20.0599C2.03614 19.725 2.21078 19.4628 2.56004 18.9383Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default IconUsers
