import { Box, Card, CardContent, Stack } from '@mui/material'

export const ONE_COLUMN_LAYOUT_PADDING = 24

const OneColumnLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ padding: `${ONE_COLUMN_LAYOUT_PADDING}px` }}>{children}</Box>
  )
}

OneColumnLayout.Thin = ({ content }: { content: React.ReactNode[] }) => {
  return (
    <Box
      sx={{
        padding: `${ONE_COLUMN_LAYOUT_PADDING}px`,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Stack spacing={3} width="55%" minWidth="600px">
        {content.map((contentItem, index) => (
          <Card key={index}>
            <CardContent>{contentItem}</CardContent>
          </Card>
        ))}
      </Stack>
    </Box>
  )
}

export default OneColumnLayout
