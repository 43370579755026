import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useIdleTimer } from 'react-idle-timer'

import { Redirect, routes, useLocation, useParams } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import useFeature from 'src/hooks/useFeature'
import auth0Storage from 'src/lib/localStorage/auth0Storage'
import logger from 'src/lib/logger'

const IDLE_TIMEOUT_MS = 3 * 60 * 60 * 1000

const AuthValidator = ({ children }: { children: React.ReactNode }) => {
  const {
    userMetadata,
    currentUser,
    loading,
    reauthenticate,
    client: authClient,
  } = useAuth()
  const { orgName } = useParams()
  const {
    trainingEnabled,
    suppliersEnabled,
    productsEnabled,
    documentsEnabled,
    idleTimeoutLogoutAndPeriodicTokenRefetch,
  } = useFeature()
  const { pathname } = useLocation()

  // Log out after inactivity across all tabs
  useIdleTimer({
    crossTab: true,
    syncTimers: 1000,
    timeout: IDLE_TIMEOUT_MS,
    // debounce the onAction call by 3 minutes
    debounce: 3 * 60 * 1_000,
    onAction: async () => {
      if (idleTimeoutLogoutAndPeriodicTokenRefetch) {
        authClient
          ?.getTokenSilently({
            cacheMode: 'off',
          })
          .catch(async (e) => {
            // just log error so that people don't end up
            // with unexpected refreshes on their page without
            // any actions
            logger.error(`Reauthentication error: ${e}`)
          })
      }
    },
    onIdle: async () => {
      await authClient?.logout({
        logoutParams: {},
      })
    },
  })

  const ldClient = useLDClient()
  React.useEffect(() => {
    ldClient &&
      !loading &&
      ldClient
        .identify({
          key: currentUser?.email ?? 'anonymous',
          email: currentUser?.email,
          custom: {
            organization: orgName,
          },
        })
        .catch((e) => {
          logger.error(`Error: ${e}`)
        })
  }, [currentUser?.email, ldClient, loading, orgName])

  if (auth0Storage.isTokenExpired()) {
    // Attempt reauth if the token is expired. This will use the refresh token.
    // If the refresh token is also expired due to inactivity, this will
    // fall back on the session expiration settings and log the user
    // out if needed
    reauthenticate().catch((e) =>
      logger.error('Received error when trying to reauthenticate', e)
    )
  }

  if (loading) {
    return null
  }

  // Add redirection for the user if the user is not logged in
  if (currentUser == null && userMetadata == null) {
    return <Redirect to={routes.login({ orgName })} />
  }
  if (currentUser == null) {
    if (process.env.NODE_ENV === 'development') {
      // happens often while the server is restarting
      return null
    }
    return <Redirect to={routes.unauthorizedOrganization()} />
  }

  const currentOrgUsers = currentUser.organizationUsers.filter(
    (orgUser) => orgUser.organization.name === orgName
  )
  if (currentOrgUsers.length !== 1) {
    throw new Error(
      `${currentOrgUsers.length} orgUsers found for authUser ${currentUser.authUserId_DO_NOT_USE_UNLESS_YOU_KNOW_WHAT_YOURE_DOING}`
    )
  }

  if (
    !productsEnabled &&
    (pathname.startsWith(routes.products({ orgName })) ||
      pathname.startsWith(routes.integrations({ orgName })) ||
      pathname.startsWith(routes.importExport({ orgName })))
  ) {
    return <Redirect to={'/404'} />
  }
  if (
    !documentsEnabled &&
    (pathname.startsWith(routes.documents({ orgName })) ||
      pathname.startsWith(
        routes.changeOrders({ orgName }) ||
          pathname.startsWith(routes.documentTypes({ orgName }))
      ))
  ) {
    return <Redirect to={'/404'} />
  }
  if (!suppliersEnabled && pathname.startsWith(routes.suppliers({ orgName }))) {
    return <Redirect to={'/404'} />
  }
  if (
    !trainingEnabled &&
    (pathname.startsWith(routes.trainingAssignments({ orgName })) ||
      pathname.startsWith(routes.trainingSettings({ orgName })))
  ) {
    return <Redirect to={'/404'} />
  }

  return <>{children}</>
}

export default AuthValidator
