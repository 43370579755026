import type { SVGProps } from 'react'
const IconGraduationHat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 14.4999V11.4944C17 11.3149 17 11.2252 16.9727 11.1459C16.9485 11.0759 16.9091 11.012 16.8572 10.9591C16.7986 10.8992 16.7183 10.8591 16.5578 10.7788L12 8.49994M4 9.49994V16.3066C4 16.6785 4 16.8644 4.05802 17.0272C4.10931 17.1712 4.1929 17.3014 4.30238 17.408C4.42622 17.5286 4.59527 17.6061 4.93335 17.7611L11.3334 20.6944C11.5786 20.8068 11.7012 20.863 11.8289 20.8852C11.9421 20.9048 12.0579 20.9048 12.1711 20.8852C12.2988 20.863 12.4214 20.8068 12.6666 20.6944L19.0666 17.7611C19.4047 17.6061 19.5738 17.5286 19.6976 17.408C19.8071 17.3014 19.8907 17.1712 19.942 17.0272C20 16.8644 20 16.6785 20 16.3066V9.49994M2 8.49994L11.6422 3.67883C11.7734 3.61324 11.839 3.58044 11.9078 3.56753C11.9687 3.5561 12.0313 3.5561 12.0922 3.56753C12.161 3.58044 12.2266 3.61324 12.3578 3.67883L22 8.49994L12.3578 13.3211C12.2266 13.3866 12.161 13.4194 12.0922 13.4324C12.0313 13.4438 11.9687 13.4438 11.9078 13.4324C11.839 13.4194 11.7734 13.3866 11.6422 13.3211L2 8.49994Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default IconGraduationHat
