import { Fab, Stack, SxProps, useTheme } from '@mui/material'

import { Toaster, toast } from '@redwoodjs/web/toast'

import ConfirmationDialog from 'src/components/ConfirmationDialog'
import MainNav from 'src/components/MainNav'
import SettingsNav from 'src/components/SettingsNav'
import IconMessageTextSquare01 from 'src/images/icons/IconMessageTextSquare01'
import useIntercom from 'src/lib/intercom/useIntercom'
import toastStorage from 'src/lib/sessionStorage/toastStorage'
import useNavStore from 'src/stores/useNavStore'

type MainLayoutProps = {
  nav?: 'BASE' | 'SETTINGS'
  fabPlacement?: SxProps
  children: React.ReactNode
}

const MainLayout = ({
  nav = 'BASE',
  fabPlacement,
  children,
}: MainLayoutProps) => {
  const { palette } = useTheme()

  useIntercom()
  const { navWidth } = useNavStore()

  React.useEffect(() => {
    const toastFlash = toastStorage.get()
    if (toastFlash) {
      toast.success(toastFlash)
      toastStorage.clear()
    }
  }, [])

  return (
    <>
      <div data-testid="global-toaster">
        <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
      </div>

      <ConfirmationDialog />

      {nav === 'BASE' ? (
        <MainNav>{children}</MainNav>
      ) : nav === 'SETTINGS' ? (
        <SettingsNav>{children}</SettingsNav>
      ) : null}

      <Stack
        id="bottom-left-fab-stack"
        sx={{
          flexDirection: 'column-reverse',
          gap: 1,
          position: 'fixed',

          ...(fabPlacement ?? {
            left: `${navWidth + 16}px`,
            bottom: '24px',
          }),
        }}
      >
        <Fab id="intercom-custom-launcher" size="small" color="primary">
          <IconMessageTextSquare01 color={palette.common.white} />
        </Fab>
      </Stack>
    </>
  )
}

MainLayout.RightFabs = ({ children }: { children: React.ReactNode }) => {
  return (
    <MainLayout
      fabPlacement={{
        right: '12px',
        bottom: '12px',
      }}
    >
      {children}
    </MainLayout>
  )
}

export default MainLayout
