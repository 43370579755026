import { Box, ThemeOptions } from '@mui/material'
import type { Theme } from '@mui/material/styles'
import { alpha, createTheme } from '@mui/material/styles'
import { GridOverlay } from '@mui/x-data-grid-pro'
import type {} from '@mui/x-data-grid/themeAugmentation'

import {
  IconAlert,
  IconCheckboxChecked,
  IconCheckboxUnchecked,
  IconCheckboxUncheckedDarkMode,
  IconChevronDown,
  IconChevronUp,
  IconInfo,
} from 'src/components/Icon'

declare module '@mui/material' {
  interface Color {
    border: string
    light: {
      [key: number]: string
    }
    dark: {
      [key: number]: string
    }
  }

  interface ThemeOptions {
    customShadows: string[]
  }
  interface Theme {
    customShadows: string[]
  }

  interface PaletteOptions {
    status: {
      [key: string]: string
    }
  }
  interface Palette {
    status: {
      [key: string]: string
    }
  }
}

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    fontWeightSemibold: number
  }
  interface Typography {
    fontWeightSemibold: number
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    soft: true
    ghost: true
    'outlined-danger': true
    'ghost-danger': true
  }
}

declare module '@mui/x-data-grid/components/toolbar/GridToolbarFilterButton' {
  interface GridToolbarFilterButtonProps {
    variant?: 'outlined' | 'soft' | 'ghost' | 'outlined-danger' | 'ghost-danger'
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    rounded: true
    'outlined-rounded': true
  }
}

const buttonOverrides: NonNullable<ThemeOptions['components']>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ theme: { palette, typography, customShadows } }) => ({
      textTransform: 'none',
      fontWeight: typography.fontWeightSemibold,
      fontSize: typography.body2.fontSize,
      color: palette.common.white,
      boxShadow: customShadows[0],
      borderRadius: '6px',
      height: '40px',
      padding: '0 12px',

      '& .MuiButton-startIcon': {
        width: '20px',
        margin: '0 0.5rem 0 0.25rem',
      },
      '& .MuiButton-startIcon > svg > path': {
        stroke: palette.common.white,
      },

      '&:hover': {
        backgroundColor: palette.primary.dark,
      },
      '&.Mui-disabled': {
        color: palette.common.white,
        backgroundColor: alpha(palette.primary.main, 0.4),
      },
    }),
  },
  variants: [
    {
      props: { variant: 'outlined' },
      style: ({ theme: { palette, typography } }) => ({
        fontWeight: typography.fontWeightMedium,
        border: `1px solid ${palette.grey.border}`,
        backgroundColor:
          palette.mode === 'dark' ? 'transparent' : palette.background.paper,
        color: palette.text.primary,

        '& .MuiButton-startIcon > svg > path': {
          stroke: palette.text.primary,
        },

        '&:hover': {
          border: `1px solid ${palette.grey.border}`,
          backgroundColor: palette.grey.light[100],
        },
        '&.Mui-disabled': {
          color: alpha(palette.text.primary, 0.4),
          border: `1px solid ${alpha(palette.grey.border, 0.4)}`,
          backgroundColor:
            palette.mode === 'dark' ? 'transparent' : palette.background.paper,
        },
        '&.Mui-disabled .MuiButton-startIcon > svg > path': {
          stroke: alpha(palette.text.primary, 0.4),
        },
      }),
    },
    {
      props: { variant: 'soft' },
      style: ({ theme: { palette, typography } }) => ({
        boxShadow: 'none',
        fontWeight: typography.fontWeightMedium,
        backgroundColor: palette.grey.light[100],
        color: palette.text.primary,

        '& .MuiButton-startIcon > svg > path': {
          stroke: palette.text.primary,
        },

        '&:hover': {
          backgroundColor: palette.grey.light[200],
        },
        '&.Mui-disabled': {
          backgroundColor: palette.grey.light[100],
          color: alpha(palette.text.primary, 0.4),
        },
        '&.Mui-disabled .MuiButton-startIcon > svg > path': {
          stroke: alpha(palette.text.primary, 0.4),
        },
      }),
    },
    {
      props: { variant: 'ghost' },
      style: ({ theme: { palette, typography } }) => ({
        boxShadow: 'none',
        fontWeight: typography.fontWeightMedium,
        backgroundColor:
          palette.mode === 'dark' ? 'transparent' : palette.background.paper,
        color: palette.text.secondary,

        '& .MuiButton-startIcon > svg > path': {
          stroke: palette.text.secondary,
        },

        '&:hover': {
          backgroundColor: palette.grey.light[100],
          color: palette.text.secondary,
        },
        '&.Mui-disabled': {
          backgroundColor:
            palette.mode === 'dark' ? 'transparent' : palette.background.paper,
          color: alpha(palette.text.secondary, 0.4),
        },
        '&.Mui-disabled .MuiButton-startIcon > svg > path': {
          stroke: alpha(palette.text.secondary, 0.4),
        },
      }),
    },
    {
      props: { variant: 'outlined-danger' },
      style: ({ theme: { palette, typography } }) => ({
        fontWeight: typography.fontWeightMedium,
        border: `1px solid ${palette.status.error}`,
        backgroundColor: palette.background.paper,
        color: palette.status.error,

        '& .MuiButton-startIcon > svg > path': {
          stroke: palette.status.error,
        },

        '&:hover': {
          border: `1px solid ${palette.status.error}`,
          backgroundColor: alpha(palette.status.error, 0.1),
        },

        '&.Mui-disabled': {
          border: `1px solid ${alpha(palette.status.error, 0.4)}`,
          backgroundColor: 'transparent',
          color: alpha(palette.status.error, 0.4),
          '& .MuiButton-startIcon > svg > path': {
            stroke: alpha(palette.status.error, 0.4),
          },
        },
      }),
    },
    {
      props: { variant: 'ghost-danger' },
      style: ({ theme: { palette, typography } }) => ({
        boxShadow: 'none',
        fontWeight: typography.fontWeightMedium,
        backgroundColor: palette.background.paper,
        color: palette.status.error,

        '& .MuiButton-startIcon > svg > path': {
          stroke: palette.status.error,
        },

        '&:hover': {
          backgroundColor: alpha(palette.status.error, 0.1),
        },

        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          color: alpha(palette.status.error, 0.4),
          '& .MuiButton-startIcon > svg > path': {
            stroke: alpha(palette.status.error, 0.4),
          },
        },
      }),
    },
    {
      props: { size: 'small' },
      style: {
        height: '32px',
        padding: '0 8px',
      },
    },
  ],
}

const dataGridFilterInputOverrides = ({
  theme: { palette, typography, customShadows },
}: {
  theme: Pick<Theme, 'palette' | 'typography' | 'customShadows'>
}) => ({
  '& > .MuiInputLabel-root': {
    '&.Mui-focused': {
      '&::before, &::after': { borderBottom: 'none' },
    },
  },

  '& .MuiInputBase-root': {
    marginTop: 0,
    '&::before, &::after': { borderBottom: 'none' },

    '& > .MuiInputBase-input': {
      display: 'flex',
      alignItems: 'center',
      minHeight: '40px',
      boxSizing: 'border-box',
      border: `1px solid ${palette.grey.border}`,
      padding: '0 11px',
      backgroundColor:
        palette.mode === 'dark' ? 'transparent' : palette.background.paper,
      boxShadow: customShadows[0],
      borderRadius: '8px',
      fontSize: typography.body2.fontSize,
      paddingRight: '24px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    '&:hover': {
      '&::before, &::after': { borderBottom: 'none' },
      '& > .MuiInputBase-input': {
        paddingRight: '24px',
        border: `1px solid ${palette.grey.dark[100]}`,
      },
    },
    '&.Mui-focused': {
      '&::before, &::after': { borderBottom: 'none' },
      '& > .MuiInputBase-input': {
        paddingRight: '24px',
        border: `2px solid ${palette.primary.main}`,
      },
    },

    '&.MuiAutocomplete-inputRoot': {
      minHeight: '40px',
      '& > .MuiAutocomplete-tag': {
        '& > .MuiChip-deleteIcon': {
          width: '15px',
          height: '15px',
          marginRight: '4px',
          '& > path': { stroke: 'unset' },
        },
      },
    },
  },
})

export default (darkMode: boolean) => {
  const white = '#ffffff'
  const black = '#12131c'

  const themeWhite = darkMode ? black : white
  const themeBlack = darkMode ? white : black

  const greyLight100 = darkMode ? '#181922' : '#f6f6f6'
  const greyLight200 = darkMode ? '#22232e' : '#ededed'
  const greyDark100 = darkMode ? '#575967' : '#bababa'
  const greyDark200 = darkMode ? '#8e8f9d' : '#666666'
  const greyDark300 = darkMode ? '#ffffff' : '#333333'
  const borderColor = darkMode ? '#2d303d' : '#eaeaea'

  const errorColor = '#df5151'
  const warningColor = '#cb8806'
  const infoColor = '#1371ec'
  const successColor = '#52ab76'

  return createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      common: {
        white: themeWhite,
        black: themeBlack,
      },
      primary: {
        light: darkMode ? '#140c00' : '#fbf7f1',
        main: '#e99a00',
        dark: darkMode ? '#f8a90d' : '#cb8806',
      },
      grey: {
        light: {
          100: greyLight100,
          200: greyLight200,
        },
        dark: {
          100: greyDark100,
          200: greyDark200,
          300: greyDark300,
        },
        border: borderColor,
      },
      divider: borderColor,
      background: {
        default: greyLight100,
        paper: themeWhite,
      },
      text: {
        primary: greyDark300,
        secondary: greyDark200,
      },

      error: { main: errorColor },
      warning: { main: warningColor },
      info: { main: infoColor },
      success: { main: successColor },

      status: {
        error: errorColor,
        warning: warningColor,
        info: infoColor,
        success: successColor,
        review: '#1371eC',
        approval: '#682ee4',
        pending: '#682EE4',
        critical: '#B02BA2',
      },
    },

    customShadows: [
      '0px 1px 2px rgba(51, 51, 51, 0.04)',
      '0px 1px 4px rgba(51, 51, 51, 0.12)',
      '0px 2px 8px rgba(51, 51, 51, 0.12)',
      '0px 4px 12px rgba(51, 51, 51, 0.12)',
    ],

    typography: {
      fontSize: 13,
      fontFamily: ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightSemibold: 600,
      fontWeightBold: 700,
      h1: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '28px',
      },
      h2: {
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
      },
      h3: {
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
      },
      body1: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      body2: {
        fontSize: '13px',
        lineHeight: '20px',
      },
      caption: {
        fontSize: '12px',
        lineHeight: '16px',
      },
      overline: {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
      },
    },

    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: ({ theme: { palette } }) => {
            return {
              '&.MuiListItemButton-root': {
                paddingTop: '4px',
                paddingBottom: '4px',

                ...(darkMode
                  ? {
                      '&:hover': {
                        backgroundColor: alpha(palette.grey.dark[300], 0.12),
                      },
                      '&.Mui-selected': {
                        backgroundColor: palette.grey.light[200],
                        '&:hover': {
                          backgroundColor: alpha(palette.grey.dark[300], 0.12),
                        },
                      },
                    }
                  : {}),
              },

              '&.MuiIconButton-sizeSmall': {
                height: '32px',
                width: '32px',
                padding: 0,
              },
            }
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variant: 'body2',
        },
        styleOverrides: {
          root: ({ theme: { palette } }) => ({
            color: palette.text.primary,
          }),
        },
      },

      MuiButton: buttonOverrides,
      MuiLoadingButton: buttonOverrides,

      MuiInputLabel: {
        styleOverrides: {
          root: ({ theme: { palette, typography } }) => ({
            marginBottom: '4px',
            color: palette.text.primary,
            fontWeight: typography.fontWeightMedium,
            fontSize: typography.fontSize,
            transformOrigin: 'unset',
            whiteSpace: 'unset',
            overflow: 'unset',
            textOverflow: 'unset',
            maxWidth: 'unset',
            position: 'unset',
            left: 'unset',
            top: 'unset',
            transform: 'unset',
            transition: 'unset',
            zIndex: 'unset',
            pointerEvents: 'unset',

            '&.Mui-focused': {
              color: palette.text.primary,
            },
            '&.Mui-error': {
              color: palette.text.primary,
            },
            '&.Mui-disabled': {
              color: alpha(palette.text.primary, 0.5),
            },
          }),
        },
      },

      MuiFormLabel: {
        styleOverrides: {
          asterisk: ({ theme: { palette } }) => ({
            color: palette.primary.main,
          }),
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
          },
        },
      },

      MuiTextField: {
        defaultProps: {
          InputLabelProps: {
            shrink: true,
          },
          SelectProps: {
            IconComponent: ({ className }) => {
              const isMenuOpen = className?.includes('MuiSelect-iconOpen')
              const isInlineSelect = className?.includes(
                'MuiSelect-iconStandard'
              )

              return (
                <Box
                  sx={{
                    position: 'absolute',
                    right: isInlineSelect ? 0 : '17px',
                    top: isInlineSelect ? 0 : '8px',
                    pointerEvents: 'none',
                  }}
                >
                  {isMenuOpen ? (
                    <IconChevronUp color="#666666" />
                  ) : (
                    <IconChevronDown color="#666666" />
                  )}
                </Box>
              )
            },
          },
        },
        styleOverrides: {
          root: ({ theme: { palette, typography, customShadows } }) => ({
            // =============== Default ===============
            '& > .MuiInputBase-root': {
              '& > .MuiInputBase-input': {
                height: '40px',
                boxSizing: 'border-box',
                border: `1px solid ${palette.grey.border}`,
                padding: '0 11px',
                backgroundColor:
                  palette.mode === 'dark'
                    ? 'transparent'
                    : palette.background.paper,
                boxShadow: customShadows[0],
                borderRadius: '8px',
                fontSize: typography.body2.fontSize,

                // vvvvv Select vvvvv
                '&.MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',

                  '& > .select-placeholder': {
                    color: palette.grey.dark[100],
                  },

                  '&.Mui-disabled': {
                    '& ~ .MuiBox-root > svg': {
                      opacity: 0.5,
                    },
                  },
                },
                // ^^^^^ Select ^^^^^
              },
              '& > .MuiOutlinedInput-notchedOutline': {
                display: 'none',
              },

              '&:hover': {
                '& > .MuiInputBase-input': {
                  border: `1px solid ${palette.grey.dark[100]}`,
                },
              },
              '&.Mui-focused': {
                '& > .MuiInputBase-input': {
                  border: `2px solid ${palette.primary.main}`,
                  padding: '0 10px',
                },
              },
              '&.Mui-error': {
                '& > .MuiInputBase-input': {
                  border: `2px solid ${palette.status.error}`,
                  padding: '0 10px',
                },
              },
              '&.Mui-disabled': {
                '& > .MuiInputBase-input': {
                  border: `1px solid ${alpha(palette.grey.border, 0.5)}`,
                  color: alpha(palette.text.secondary, 0.5),
                },
              },
            },

            // =============== Multiline ===============
            '& > .MuiInputBase-root.MuiInputBase-multiline': {
              padding: 0,

              '& > .MuiInputBase-input': {
                height: 'auto',
                padding: '9px 11px',
                resize: 'vertical',
                '&:not([readonly])': {
                  minHeight: '40px',
                },
              },

              '&:hover': {
                '& > .MuiInputBase-input': {
                  border: `1px solid ${palette.grey.dark[100]}`,
                },
              },
              '&.Mui-focused': {
                '& > .MuiInputBase-input': {
                  border: `2px solid ${palette.primary.main}`,
                  padding: '8px 10px',
                },
              },
              '&.Mui-error': {
                '& > .MuiInputBase-input': {
                  border: `2px solid ${palette.status.error}`,
                  padding: '8px 10px',
                },
              },
              '&.Mui-disabled': {
                '& > .MuiInputBase-input': {
                  border: `1px solid ${alpha(palette.grey.border, 0.5)}`,
                  color: alpha(palette.text.secondary, 0.5),
                },
              },
            },

            // =============== Start icon - non Autocomplete (e.g. search) ===============
            '& > .MuiInputBase-root.MuiInputBase-adornedStart:not(.MuiAutocomplete-inputRoot)':
              {
                padding: 0,

                '& > .MuiInputAdornment-root': {
                  position: 'absolute',
                  left: '15px',
                  '& > svg': {
                    width: '18px',
                  },
                },

                '& > .MuiInputBase-input': {
                  padding: '0 11px 0 41px',
                },

                '&.Mui-focused': {
                  '& > .MuiInputBase-input': {
                    padding: '0 10px 0 40px',
                  },
                },
                '&.Mui-error': {
                  '& > .MuiInputBase-input': {
                    padding: '0 10px 0 40px',
                  },
                },

                '&.MuiInputBase-sizeSmall': {
                  '& > .MuiInputAdornment-root': {
                    '& > svg': {
                      width: '15px',
                    },
                  },
                },
              },

            // =============== Start icon - Autocomplete  ===============
            '& > .MuiInputBase-root.MuiInputBase-adornedStart.MuiAutocomplete-inputRoot':
              {
                '& > svg': { marginLeft: '11px' },

                '& > .MuiInputAdornment-root': {
                  position: 'relative',
                  left: '8px',
                  '& > svg': {
                    width: '18px',
                  },
                },

                '&.MuiInputBase-sizeSmall': {
                  '& > .MuiInputAdornment-root': {
                    '& > svg': {
                      width: '15px',
                    },
                  },
                },
              },

            // =============== Autocomplete ===============
            '& > .MuiInputBase-root.MuiAutocomplete-inputRoot': {
              border: `1px solid ${palette.grey.border}`,
              padding: '1px 39px 1px 1px',
              boxShadow: customShadows[0],
              borderRadius: '8px',

              '& > .MuiInputBase-input': {
                height: 'auto',
                border: 'none',
                boxShadow: 'none',
                padding: '6.5px 5px',
              },

              '& > .MuiAutocomplete-endAdornment': {
                right: '17px',
                '& > .MuiAutocomplete-popupIndicator': {
                  top: '3px',
                },
                '& > .MuiAutocomplete-clearIndicator': {
                  right: '6px',
                  '& ~ .MuiAutocomplete-popupIndicator': {
                    top: '1px',
                  },
                },
              },

              '&:hover': {
                border: `1px solid ${palette.grey.dark[100]}`,
              },
              '&.Mui-focused': {
                border: `2px solid ${palette.primary.main}`,
                padding: '0 65px 0 0',
                '& > .MuiAutocomplete-endAdornment': {
                  right: '16px',
                },
              },
              '&.Mui-error': {
                border: `2px solid ${palette.status.error}`,
                padding: '0 65px 0 0',
                '& > .MuiAutocomplete-endAdornment': {
                  right: '16px',
                },
              },
              '&.Mui-disabled': {
                border: `1px solid ${alpha(palette.grey.border, 0.5)}`,
                color: alpha(palette.text.secondary, 0.5),
                '& > .MuiAutocomplete-endAdornment': {
                  '& > .MuiAutocomplete-popupIndicator': {
                    '& > svg': {
                      color: alpha(palette.text.secondary, 0.5),
                    },
                  },
                },
              },
            },

            // =============== Inline (no borders) ===============
            '& > .MuiInputBase-root:not(.MuiOutlinedInput-root)': {
              paddingBottom: '1px',
              '&::before, &::after': { border: 'none' },

              '& > .MuiInputBase-input': {
                height: 'auto',
                border: 'none',
                borderRadius: 0,
                padding: 0,
                boxShadow: 'none',
                fontWeight: typography.fontWeightMedium,

                // vvvvv Select vvvvv
                '&.MuiSelect-select': {
                  paddingRight: '35px',
                },
                // ^^^^^ Select ^^^^^
              },

              '&:hover': {
                '&::before, &::after': { border: 'none' },
              },

              '&.Mui-error': {
                borderBottom: `1px dashed ${palette.status.error}`,
                paddingBottom: 0,
              },

              // vvvvv Multiline vvvvv
              '&.MuiInputBase-multiline': {
                padding: 0,
                '&::before, &::after': { border: 'none' },

                '& > .MuiInputBase-input': {
                  minHeight: 'auto',
                  border: 'none',
                  borderRadius: 0,
                  padding: 0,
                  boxShadow: 'none',
                  fontWeight: typography.fontWeightMedium,
                  resize: 'none',
                },

                '&:hover': {
                  '& > .MuiInputBase-input': {
                    padding: 0,
                  },
                },
                '&.Mui-focused': {
                  '& > .MuiInputBase-input': {
                    padding: 0,
                  },
                },
              },
              // ^^^^^ Multiline ^^^^^
            },
          }),
        },
        variants: [
          {
            props: { size: 'small' },
            style: {
              '& > .MuiInputBase-root': {
                '& > .MuiInputBase-input': {
                  height: '32px',
                },
              },
            },
          },
        ],
      },

      MuiAutocomplete: {
        defaultProps: {
          popupIcon: <IconChevronDown color="#666666" />,
          componentsProps: {
            paper: {
              elevation: 8,
            },
          },
        },
        styleOverrides: {
          root: ({ theme: { palette } }) => ({
            '& .MuiAutocomplete-inputRoot': {
              padding: 0,
            },
            '& .MuiChip-root': {
              '& > svg': {
                marginLeft: 0,
                marginRight: '10px',
                width: '8px',

                '& > path': {
                  stroke: palette.grey.dark[200],
                  strokeWidth: 2,
                },
              },
            },
          }),

          paper: ({ theme: { palette, typography, customShadows } }) => ({
            borderRadius: '8px',
            boxShadow: customShadows[2],

            '& .MuiAutocomplete-option': {
              minHeight: '40px',
              fontWeight: typography.fontWeightMedium,
              fontSize: typography.body2.fontSize,

              '&[aria-selected=true]': {
                borderLeft: `2px solid ${palette.primary.main}`,
                paddingLeft: '14px',
              },
            },
          }),
        },
      },

      MuiCheckbox: {
        defaultProps: {
          icon: darkMode ? (
            <IconCheckboxUncheckedDarkMode />
          ) : (
            <IconCheckboxUnchecked />
          ),
          // icon: <IconCheckboxUnchecked fill={darkMode ? '#22232e' : undefined} />,
          checkedIcon: <IconCheckboxChecked />,
        },
        styleOverrides: {
          root: ({ theme: { palette, typography } }) => ({
            '& ~ .MuiFormControlLabel-label': {
              fontWeight: typography.fontWeightMedium,
              fontSize: typography.body2.fontSize,
            },

            '&:hover': {
              '&:hover': {
                '& > svg > g > rect': {
                  fill: palette.grey.light[100],
                },
              },
            },

            '&.Mui-checked': {
              '&:hover': {
                '& > svg > g > rect': {
                  fill: palette.primary.dark,
                },
              },
            },
            '&.Mui-disabled': {
              '& > svg > g > rect': {
                fill: palette.grey.light[100],
              },
              '& ~ .MuiTypography-root': {
                color: palette.grey.dark[100],
              },
            },
          }),
        },
      },

      MuiRadio: {
        styleOverrides: {
          root: ({ theme: { palette } }) => ({
            color: palette.grey.border,
            padding: '4px 9px',
            '&.Mui-checked:hover': {
              color: palette.primary.dark,
            },
          }),
        },
      },

      MuiSwitch: {
        styleOverrides: {
          root: ({ theme: { palette, transitions } }) => ({
            width: 32,
            height: 20,
            padding: 0,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              margin: 2,
              transitionDuration: '300ms',
              '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                  backgroundColor: palette.primary.main,
                  opacity: 1,
                  border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.5,
                },
                '&:hover + .MuiSwitch-track': {
                  backgroundColor: palette.primary.dark,
                },
              },
              '&.Mui-disabled .MuiSwitch-thumb': {
                color: palette.grey[100],
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
              },
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              width: 16,
              height: 16,
            },
            '& .MuiSwitch-track': {
              borderRadius: 26 / 2,
              backgroundColor: palette.grey.light[200],
              opacity: 1,
              transition: transitions.create(['background-color'], {
                duration: 500,
              }),
            },
          }),
        },
      },

      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            '&:before': {
              display: 'none',
            },
            '&:last-of-type': {
              borderRadius: '8px',
            },
            '&.Mui-expanded': {
              margin: 0,
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            '&.Mui-expanded': {
              minHeight: '48px',
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'transparent',
            },
          },
          content: {
            '&.Mui-expanded': {
              margin: '12px 0',
            },
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          root: ({ theme: { palette } }) => ({
            backgroundColor: palette.grey.light[100],
            borderRadius: '6px',

            '& > svg.MuiChip-avatar': {
              width: '18px',
              marginLeft: '8px',
            },

            '&.MuiChip-sizeSmall': {
              height: '20px',
              '& > svg.MuiChip-avatar': {
                width: '15px',
                marginLeft: '4px',
                marginRight: '-3px',
              },
            },
          }),
        },
        variants: [
          {
            props: { variant: 'outlined' },
            style: ({ theme: { palette } }) => ({
              backgroundColor:
                palette.mode === 'dark'
                  ? 'transparent'
                  : palette.background.paper,
              border: `1px solid ${palette.grey.border}`,
            }),
          },
          {
            props: { variant: 'rounded' },
            style: {
              borderRadius: '999px',
            },
          },
          {
            props: { variant: 'outlined-rounded' },
            style: ({ theme: { palette } }) => ({
              backgroundColor:
                palette.mode === 'dark'
                  ? 'transparent'
                  : palette.background.paper,
              border: `1px solid ${palette.grey.border}`,
              borderRadius: '999px',
            }),
          },
        ],
      },

      MuiMenu: {
        styleOverrides: {
          root: ({ theme: { customShadows } }) => ({
            '& > .MuiMenu-paper': {
              boxShadow: customShadows[2],

              '& > .MuiMenu-list': {
                borderRadius: '8px',
                padding: '4px 0',
              },
            },
          }),
        },
      },

      MuiMenuItem: {
        styleOverrides: {
          root: ({ theme: { palette, typography } }) => ({
            height: '40px',
            fontWeight: typography.fontWeightMedium,
            fontSize: typography.body2.fontSize,

            '& .MuiListItemIcon-root': {
              minWidth: '25px',

              '& > svg': {
                width: '15px',
                '& > path': {
                  strokeWidth: '1.75px',
                },
              },
            },

            '& .MuiListItemText-primary': {
              fontWeight: typography.fontWeightMedium,
              fontSize: typography.body2.fontSize,
            },

            '&.Mui-selected': {
              borderLeft: `2px solid ${palette.primary.main}`,
              paddingLeft: '14px',
            },
          }),
        },
      },

      MuiDrawer: {
        defaultProps: {
          anchor: 'right',
        },
        styleOverrides: {
          root: ({ theme: { customShadows } }) => ({
            '& .MuiDrawer-paper': {
              boxShadow: customShadows[2],
              width: '480px',
              height: 'auto',
              top: '8px',
              bottom: '8px',
              right: '8px',
              borderRadius: '8px',
            },
          }),
        },
      },

      MuiDataGrid: {
        defaultProps: {
          columnHeaderHeight: 40,
          rowHeight: 48,
          slots: {
            noRowsOverlay: () => <GridOverlay>No results</GridOverlay>,
          },
        },
        styleOverrides: {
          root: ({ theme: { palette, typography } }) => ({
            border: 0,

            '& .MuiDataGrid-virtualScrollerRenderZone': {
              borderRadius: '8px',
            },

            '& .MuiDataGrid-columnHeaders': {
              border: 0,

              '& .MuiDataGrid-columnHeaderCheckbox': {
                '&:focus-within': {
                  outline: 'none',
                },
              },

              '& > [role=row]': {
                borderRadius: '8px 8px 0 0',
              },

              '& .MuiDataGrid-columnHeader': {
                border: 0,

                '& .MuiDataGrid-columnHeaderTitle': {
                  textTransform: 'uppercase',
                  fontSize: typography.overline.fontSize,
                  fontWeight: typography.fontWeightSemibold,
                  color: palette.text.secondary,
                },

                '&:focus': {
                  outline: 'none',
                },
              },
            },
            '& .MuiDataGrid-row': {
              width: 'calc(100% - 2px)',
              border: `1px solid ${palette.grey.border}`,

              '&:last-of-type': {
                borderRadius: '0 0 8px 8px',
              },

              '&:not(:last-of-type)': {
                borderBottom: 'none',
              },
              backgroundColor: palette.background.paper,
              boxSizing: 'border-box',

              '& .MuiDataGrid-cell': {
                borderBottom: 0,

                '&:focus-within': {
                  outline: 'none',
                },

                display: 'flex',
                alignItems: 'center',
              },
            },

            '& .MuiDataGrid-footerContainer': {
              backgroundColor: palette.background.paper,
            },

            '& .MuiDataGrid-treeDataGroupingCellToggle': {
              minWidth: '32px',
            },
          }),
          paper: ({ theme: { customShadows } }) => ({
            boxShadow: customShadows[3],
            borderRadius: '8px',
          }),
          filterForm: ({ theme: { palette } }) => ({
            padding: '12px 16px',
            gap: '12px',
            borderBottom: `1px solid ${palette.grey.border}`,
          }),
          filterFormLogicOperatorInput: dataGridFilterInputOverrides,
          filterFormColumnInput: dataGridFilterInputOverrides,
          filterFormOperatorInput: dataGridFilterInputOverrides,
          filterFormValueInput: ({
            theme: { palette, typography, customShadows },
          }) => ({
            ...dataGridFilterInputOverrides({
              theme: { palette, typography, customShadows },
            }),

            '& > .MuiTextField-root': {
              '& > .MuiInputBase-root:not(.MuiOutlinedInput-root)': {
                marginTop: 0,

                '& > .MuiInputBase-input': {
                  height: '40px',
                  boxSizing: 'border-box',
                  border: `1px solid ${palette.grey.border}`,
                  padding: '0 11px',
                  backgroundColor:
                    palette.mode === 'dark'
                      ? 'transparent'
                      : palette.background.paper,
                  boxShadow: customShadows[0],
                  borderRadius: '8px',
                  fontSize: typography.body2.fontSize,
                },

                '&:hover': {
                  '& > .MuiInputBase-input': {
                    border: `1px solid ${palette.grey.dark[100]}`,
                  },
                },
                '&.Mui-focused': {
                  '& > .MuiInputBase-input': {
                    border: `2px solid ${palette.primary.main}`,
                    padding: '0 10px',
                  },
                },
              },
            },
          }),
          filterFormDeleteIcon: ({ theme: { palette } }) => ({
            order: 1,
            '& > .MuiButtonBase-root': {
              border: `1px solid ${palette.grey.border}`,
              borderRadius: '8px',
              height: '40px',
              width: '40px',
            },
          }),
          panelFooter: ({ theme: { palette, typography } }) => ({
            padding: '16px',

            // Add Filter button
            '& > .MuiButton-root:first-of-type': {
              order: 1,
              height: '32px',
              '& > .MuiButton-startIcon': {
                marginLeft: 0,
              },
            },

            // Remove all button
            '& > .MuiButton-root:last-of-type': {
              height: '32px',
              '& > .MuiButton-startIcon': {
                display: 'none',
              },

              // apply ghost-danger button styles
              boxShadow: 'none',
              fontWeight: typography.fontWeightMedium,
              backgroundColor: palette.background.paper,
              color: palette.status.error,

              '& .MuiButton-startIcon > svg > path': {
                stroke: palette.status.error,
              },

              '&:hover': {
                backgroundColor: alpha(palette.status.error, 0.1),
              },

              '&.Mui-disabled': {
                backgroundColor: 'transparent',
                color: alpha(palette.status.error, 0.4),
              },
            },
          }),
          toolbarContainer: {
            padding: 0,
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          toolbarQuickFilter: ({ theme: { palette } }) => ({
            padding: '1px',
            border: `1px solid ${palette.grey.border}`,
            borderRadius: '8px',
            height: '32px',
            width: '320px',

            '& > .MuiInputBase-root': {
              '& > .MuiSvgIcon-root': {
                marginLeft: '8px',
                color: palette.text.secondary,
              },
              '& > .MuiInputBase-inputTypeSearch': {
                padding: '0 !important',
              },
            },

            '&:hover': {
              padding: '1px',
              border: `1px solid ${palette.grey.dark[100]}`,
            },
            '&:focus-within': {
              padding: 0,
              border: `2px solid ${palette.primary.main}`,
            },
          }),
        },
      },

      MuiToggleButtonGroup: {
        styleOverrides: {
          root: ({ theme: { palette, typography } }) => ({
            '& .MuiToggleButton-root': {
              height: '32px',
              '& > svg:not(:only-child)': {
                marginRight: '4px',
                width: '19px',
                height: '19px',
                '& > path': {
                  strokeWidth: '2px',
                },
              },
              '& > .MuiTypography-root': {
                textTransform: 'none',
                fontWeight: typography.fontWeightMedium,
              },

              ...(darkMode
                ? {}
                : {
                    '&.Mui-selected': {
                      backgroundColor: palette.primary.light,
                      '& > svg': {
                        color: palette.primary.main,
                      },
                    },
                  }),

              '&.MuiToggleButton-sizeMedium': {
                height: '40px',
                '& > svg:not(:only-child)': {
                  marginRight: '8px',
                },
              },
            },
          }),
        },
      },

      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: 'unset',
            // backgroundColor: darkMode ? palette : undefined
          },
        },
      },

      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
      },
      MuiCard: {
        styleOverrides: {
          root: ({ theme: { palette, customShadows } }) => ({
            boxShadow: customShadows[1],
            borderRadius: '8px',
            border: darkMode ? `1px solid ${palette.grey.border}` : undefined,
          }),
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            '&:last-child': {
              paddingBottom: '16px',
            },
          },
        },
      },

      MuiTimeline: {
        styleOverrides: {
          root: ({ theme: { palette } }: { theme: Theme }) => ({
            '& .MuiTimelineItem-root': {
              '&:before': {
                flex: 0,
                padding: 0,
              },

              minHeight: '40px',

              '&:last-of-type .MuiTimelineConnector-root': {
                display: 'none',
              },
            },
            '& .MuiTimelineDot-root': {
              margin: 0,
              boxShadow: 'none',

              '& svg': {
                width: '12px',
                height: 'auto',
              },
            },
            '& .MuiTimelineConnector-root': {
              backgroundColor: palette.grey.border,
            },
            '& .MuiTimelineContent-root': {
              padding: '0 16px 16px',
            },
          }),
        },
      },

      MuiTabs: {
        styleOverrides: {
          root: ({ theme: { palette } }) => ({
            backgroundColor: palette.background.paper,
            '& .MuiTab-root': {
              textTransform: 'unset',
            },
          }),
        },
      },

      MuiSkeleton: {
        defaultProps: {
          animation: 'wave',
          variant: 'rounded',
        },
        styleOverrides: {
          root: ({ theme: { palette } }) => ({
            backgroundColor: palette.grey.light[100],

            '&::after': darkMode
              ? undefined
              : {
                  background: `linear-gradient(
                90deg,
                transparent,
                rgba(0, 0, 0, 0.08),
                transparent
              )`,
                },
          }),
        },
      },

      MuiBreadcrumbs: {
        styleOverrides: {
          root: ({ theme: { palette, typography } }) => ({
            '& .MuiBreadcrumbs-li': {
              fontWeight: 'medium',
              fontSize: typography.body2.fontSize,

              '&:last-of-type': {
                color: palette.text.primary,
              },
            },

            '& .MuiBreadcrumbs-separator': {
              color: palette.grey.dark[100],
            },
          }),
        },
      },

      MuiDialogTitle: {
        styleOverrides: {
          root: ({ theme: { palette, typography } }) => ({
            fontWeight: typography.fontWeightSemibold,
            borderBottom: `1px solid ${palette.grey.border}`,
            paddingBottom: '16px',

            '&.MuiTypography-root + .MuiDialogContent-root': {
              padding: '24px',
            },
          }),
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: ({ theme: { typography } }) => ({
            padding: '24px',
            fontSize: typography.body2.fontSize,
          }),
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: ({ theme: { typography } }) => ({
            fontSize: typography.body2.fontSize,
          }),
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: ({ theme: { palette } }) => ({
            borderTop: `1px solid ${palette.grey.border}`,
            padding: '16px 24px',
            '& > :not(:first-of-type)': {
              marginLeft: '12px',
            },
          }),
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: ({ theme: { customShadows } }) => ({
            '& .MuiDialog-paper.MuiDialog-paperFullWidth': {
              boxShadow: customShadows[2],
              width: '960px',
              minWidth: '960px',
            },

            '& .MuiDialog-paper:not(.MuiDialog-paperFullWidth)': {
              width: '600px',

              '& .MuiDialogTitle-root': {
                paddingBottom: 0,
                border: 'none',
              },
              '& .MuiDialogContent-root': {
                padding: '24px',
              },
              '& .MuiDialogActions-root': {
                padding: '0 24px 24px',
                border: 'none',
              },
            },
          }),
        },
      },

      MuiAlert: {
        defaultProps: {
          iconMapping: {
            warning: <IconAlert color="#cb8806" />,
            info: <IconInfo color="#666666" />,
          },
        },
        styleOverrides: {
          root: ({ theme: { typography } }) => ({
            height: '56px',
            alignItems: 'center',
            padding: '0 24px',
            fontWeight: typography.fontWeightMedium,
            borderRadius: 0,
          }),
        },
        variants: [
          {
            props: { severity: 'error' },
            style: ({ theme: { palette } }) => ({
              borderBottom: `1.5px solid ${palette.status.error}`,
              backgroundColor: alpha(palette.status.error, 0.12),
              color: palette.status.error,
            }),
          },
          {
            props: { severity: 'warning' },
            style: ({ theme: { palette } }) => ({
              borderBottom: `1.5px solid ${palette.status.warning}`,
              backgroundColor: alpha(palette.status.warning, 0.12),
              color: palette.status.warning,
            }),
          },
          {
            props: { severity: 'info' },
            style: ({ theme: { palette } }) => ({
              borderBottom: `1.5px solid ${palette.grey.dark[100]}`,
              backgroundColor: palette.grey.light[100],
              color: palette.text.secondary,
            }),
          },
          {
            props: { severity: 'success' },
            style: ({ theme: { palette } }) => ({
              borderBottom: `1.5px solid ${palette.status.success}`,
              backgroundColor: alpha(palette.status.success, 0.12),
              color: palette.status.success,
            }),
          },
        ],
      },

      MuiLinearProgress: {
        styleOverrides: {
          root: ({ theme: { palette } }) => ({
            '&.MuiLinearProgress-colorError': {
              backgroundColor: alpha(palette.status.error, 0.4),
              '& > .MuiLinearProgress-barColorError': {
                backgroundColor: palette.status.error,
              },
            },

            '&.MuiLinearProgress-colorWarning': {
              backgroundColor: alpha(palette.status.warning, 0.4),
              '& > .MuiLinearProgress-barColorWarning': {
                backgroundColor: palette.status.warning,
              },
            },

            '&.MuiLinearProgress-colorSuccess': {
              backgroundColor: alpha(palette.status.success, 0.4),
              '& > .MuiLinearProgress-barColorSuccess': {
                backgroundColor: palette.status.success,
              },
            },
          }),
        },
      },
    },
  })
}
