const productsQuery = gql`
  query ProductsQuery {
    products {
      _id: rootId # for apollo in momory cache so don't use it
      rootId
      latestVersionId
      designControlId
      name
      riskCount
      activeDesignReviewCount
      designElementCount
      matrixId
      deletedAt
    }
  }
`
export default productsQuery
