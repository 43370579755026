import { Auth0Client } from '@auth0/auth0-spa-js'

import { createAuth } from '@redwoodjs/auth-auth0-web'

import { getAuth0RedirectUri } from './utils'

const auth0 = new Auth0Client({
  domain: process.env.AUTH0_DOMAIN,
  clientId: process.env.AUTH0_CLIENT_ID,

  authorizationParams: {
    redirect_uri: getAuth0RedirectUri(),
    audience: process.env.AUTH0_AUDIENCE,
    scope: process.env.AUTH0_SCOPE,
  },

  // ** NOTE ** Storing tokens in browser local storage provides persistence across page refreshes and browser tabs.
  // However, if an attacker can achieve running JavaScript in the SPA using a cross-site scripting (XSS) attack,
  // they can retrieve the tokens stored in local storage.
  // https://auth0.com/docs/libraries/auth0-spa-js#change-storage-options
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
})

export const { AuthProvider, useAuth } = createAuth(auth0)
