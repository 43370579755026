import { datadogRum } from '@datadog/browser-rum'

/* eslint-disable no-console */

const logger = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: (message?: any, ...optionalParams: any[]) => {
    console.error(message, ...optionalParams)

    // Datadog's docs say their RUM error tracking detects console.error.
    // For some reason that's not the behavior we're seeing, so track manually.
    // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#collect-errors-manually
    datadogRum.addError(new Error(message), optionalParams)
  },
  warn: console.warn,
}

export default logger

/* eslint-enable no-console */
