import {
  DesignElementStatus,
  DesignPermissions,
  DesignPermissionsQuery,
  DesignPermissionsQueryVariables,
  DesignReviewStatus,
  TestExecutionApprovalStatus,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

const QUERY = gql`
  query DesignPermissionsQuery {
    permissions {
      design {
        read
        write
        approveDesignReviews
      }
    }
  }
`

const useDesignPermissions = () => {
  const { loading, data } = useQuery<
    DesignPermissionsQuery,
    DesignPermissionsQueryVariables
  >(QUERY)

  return {
    loading,
    designPermissions: data?.permissions.design ?? null,
  }
}

export const useDesignActions = () => {
  const { designPermissions } = useDesignPermissions()
  return {
    canCreateComponents: () => canCreateComponents(designPermissions),
    canEditComponents: () => canEditComponents(designPermissions),
    canCreateDesignElements: () => canCreateDesignElements(designPermissions),
    canCreateProduct: () => canCreateProduct(designPermissions),
    canEditProduct: () => canEditProduct(designPermissions),
    canCreateDesignReview: () => canCreateDesignReview(designPermissions),
    canLinkDocumentsToProduct: () =>
      canLinkDocumentsToProduct(designPermissions),
    canEditDesignElement: (locked: boolean) =>
      canEditDesignElement(designPermissions, locked),
    canWithdrawDesignReview: (status: DesignReviewStatus) =>
      canWithdrawDesignReview(status, designPermissions),
    canSendDesignReviewForApproval: (status: DesignReviewStatus) =>
      canSendDesignReviewForApproval(status, designPermissions),
    canCancelDesignReview: (status: DesignReviewStatus) =>
      canCancelDesignReview(status, designPermissions),
    canMarkDesignElementForDeletion: (status: DesignElementStatus) =>
      canMarkDesignElementForDeletion(status, designPermissions),
    canRevertDesignElementToApproved: (status: DesignElementStatus) =>
      canRevertDesignElementToApproved(status, designPermissions),
    canImmediatelyDeleteDesignElement: (status: DesignElementStatus) =>
      canImmediatelyDeleteDesignElement(status, designPermissions),
    canApproveDesignReview: () => canApproveDesignReview(designPermissions),
    canDeleteTestExecution: (status: TestExecutionApprovalStatus) =>
      canDeleteTestExecution(status, designPermissions),
  }
}

type MaybeDesignPermissions = DesignPermissions | null

const canCreateComponents = (
  designPermissions: MaybeDesignPermissions
): boolean => {
  if (!designPermissions?.write) {
    return false
  }
  return true
}

const canEditComponents = (
  designPermissions: MaybeDesignPermissions
): boolean => {
  if (!designPermissions?.write) {
    return false
  }
  return true
}

const canCreateDesignElements = (
  designPermissions: MaybeDesignPermissions
): boolean => {
  if (!designPermissions?.write) {
    return false
  }
  return true
}

const canEditDesignElement = (
  designPermissions: MaybeDesignPermissions,
  locked: boolean
): boolean => {
  if (!designPermissions?.write) {
    return false
  }

  return !locked
}

const canCreateProduct = (
  designPermissions: MaybeDesignPermissions
): boolean => {
  if (!designPermissions?.write) {
    return false
  }
  return true
}

const canEditProduct = (designPermissions: MaybeDesignPermissions): boolean => {
  if (!designPermissions?.write) {
    return false
  }
  return true
}

const canCreateDesignReview = (
  designPermissions: MaybeDesignPermissions
): boolean => {
  if (!designPermissions?.write) {
    return false
  }
  return true
}

const canLinkDocumentsToProduct = (
  designPermissions: MaybeDesignPermissions
): boolean => {
  if (!designPermissions?.write) {
    return false
  }
  return true
}

const canWithdrawDesignReview = (
  status: DesignReviewStatus,
  designPermissions: MaybeDesignPermissions
) => {
  if (!designPermissions?.write) {
    return false
  }
  return status === 'IN_REVIEW'
}

const canDeleteTestExecution = (
  status: TestExecutionApprovalStatus,
  designPermissions: MaybeDesignPermissions
) => {
  if (!designPermissions?.write) {
    return false
  }
  return status === 'DRAFT' || status === 'REJECTED'
}

const canSendDesignReviewForApproval = (
  status: DesignReviewStatus,
  designPermissions: MaybeDesignPermissions
) => {
  if (!designPermissions?.write) {
    return false
  }
  return status === 'IN_PROGRESS'
}

const canCancelDesignReview = (
  status: DesignReviewStatus,
  designPermissions: MaybeDesignPermissions
) => {
  if (!designPermissions?.write) {
    return false
  }
  return status === 'IN_PROGRESS'
}

const canMarkDesignElementForDeletion = (
  status: DesignElementStatus,
  designPermissions: MaybeDesignPermissions
) => {
  if (!designPermissions?.write) {
    return false
  }
  return ['APPROVED', 'REVISED'].includes(status)
}

const canImmediatelyDeleteDesignElement = (
  status: DesignElementStatus,
  designPermissions: MaybeDesignPermissions
) => {
  if (!designPermissions?.write) {
    return false
  }
  return status === 'NEW'
}

const canRevertDesignElementToApproved = (
  status: DesignElementStatus,
  designPermissions: MaybeDesignPermissions
) => {
  if (!designPermissions?.write) {
    return false
  }
  return ['REVISED', 'DELETION_ASSIGNED'].includes(status)
}

const canApproveDesignReview = (
  designPermissions: MaybeDesignPermissions
): boolean => {
  if (!designPermissions?.approveDesignReviews) {
    return false
  }
  return true
}
