import {
  RejectDesignReviewMutation,
  RejectDesignReviewMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { defaultApolloErrorHandler } from 'src/utils'

const REJECT_DESIGN_REVIEW = gql`
  mutation RejectDesignReviewMutation($input: DesignReviewApprovalInput!) {
    rejectDesignReview(input: $input)
  }
`

const useRejectDesignReviewMutation = ({
  options,
}: {
  options?: GraphQLMutationHookOptions<
    RejectDesignReviewMutation,
    RejectDesignReviewMutationVariables
  >
} = {}) => {
  const [rejectDesignReview, rejectDesignReviewMutationResult] = useMutation<
    RejectDesignReviewMutation,
    RejectDesignReviewMutationVariables
  >(REJECT_DESIGN_REVIEW, {
    onError: defaultApolloErrorHandler,
    ...options,
  })

  return {
    rejectDesignReview,
    rejectDesignReviewMutationResult,
  }
}

export default useRejectDesignReviewMutation
