import { useEffect } from 'react'

import {
  RiskManagementContextQuery,
  RiskManagementContextQueryVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { useCurrentProduct } from 'src/hooks'
import useRiskManagementStore from 'src/stores/useRiskManagementStore'

export const QUERY = gql`
  query RiskManagementContextQuery($productId: Int!) {
    product(id: $productId) {
      riskMatrix {
        id
        probabilityMode
        harms(includeDeleted: true) {
          id
        }
        risks(includeDeleted: true) {
          id
        }
      }
    }
  }
`

const RiskManagementContext = ({ children }: { children: React.ReactNode }) => {
  const product = useCurrentProduct()

  const { data, loading, refetch } = useQuery<
    RiskManagementContextQuery,
    RiskManagementContextQueryVariables
  >(QUERY, {
    variables: { productId: product.rootId },
  })

  const { setRiskManagementContext, setRefetchRiskManagementContext } =
    useRiskManagementStore()

  useEffect(() => {
    if (data?.product) {
      setRiskManagementContext({ product: data.product })
    }
  }, [data, data?.product, loading, setRiskManagementContext])

  useEffect(() => {
    setRefetchRiskManagementContext(refetch)
  }, [refetch, setRefetchRiskManagementContext])

  return <>{children}</>
}

export default RiskManagementContext
