// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { useEffect } from 'react'

import { useFlags } from 'launchdarkly-react-client-sdk'

import { Router, Route, Set, PrivateSet } from '@redwoodjs/router'

import MainLayout from 'src/layouts/MainLayout'

import { useAuth } from './auth'
import AuthValidator from './components/AuthValidator/AuthValidator'
import { ProductsContext } from './contexts'
import RiskManagementContext from './contexts/RiskManagementContext'
import AdminContextLayout from './layouts/AdminContextLayout/AdminContextLayout'
import ChangeLayout from './layouts/ChangesLayout/ChangesLayout'
import ComplianceLayout from './layouts/ComplianceLayout/ComplianceLayout'
import DocumentsLayout from './layouts/DocumentsLayout/DocumentsLayout'
import InventoryLayout from './layouts/InventoryLayout/InventoryLayout'
import OnboardingLayout from './layouts/OnboardingLayout/OnboardingLayout'
import PartsLayout from './layouts/PartsLayout/PartsLayout'
import ProductLayout from './layouts/ProductLayout'
import ProductsLayout from './layouts/ProductsLayout/ProductsLayout'
import QualityEventsLayout from './layouts/QualityEventsLayout/QualityEventsLayout'
import SbomLayout from './layouts/SbomLayout/SbomLayout'
import SupplierLayout from './layouts/SuppliersLayout/SuppliersLayout'
import TrainingLayout from './layouts/TrainingLayout/TrainingLayout'
import SettingsLayout from './SettingsLayout'

const Routes = () => {
  useEffect(() => {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener('unhandledrejection', (event) => {
      // Sometimes the page will be blank after login because Redwood fails to restore application state.
      // We will redirect to root URL as a workaournd.
      // This is an example of URL that fails: http://localhost:8910/?code=gjLxFXVFxbtisPvQMxxOFqA90trWpwTJuxpsBTQ1hVKpe&state=ZU42NEZrZXg0UnZUaExVb3FvMG9vS1NhWDJWMGsyNHNKSzVUalZVYlQwMQ%3D%3D
      if (event instanceof PromiseRejectionEvent) {
        if (event.reason.message === 'Invalid state' && event.reason.stack.includes('restoreAuthState')) {
          document.location.href = '/'
        }
      }
    })
  }, [])

  const { maintenancePage } = useFlags()
  if (maintenancePage) {
    return (
      <Router useAuth={useAuth}>
        <Route path="/" page={MaintenancePage} name="maintenance" />
        <Route notfound page={MaintenancePage} />
      </Router>
    )
  }

  return (
    <Router useAuth={useAuth}>
      <PrivateSet unauthenticated="login">
        <Route path="/" page={PickOrganizationPage} name="pickOrganization" />
        <Route path="/changelog" page={ChangelogPage} name="changelog" />
        <Route path="/validation" page={ValidationPage} name="validation" />
        <Route path="/docs" page={UltralightApiDocsPage} name="ultralightApiDocs" />

        <Set wrap={[AuthValidator, MainLayout]}>
          {/* Admin */}
          <Set wrap={AdminContextLayout}>
            <Route path="/{orgName}/admin" page={AdminPage} name="admin" />
            <Route path="/{orgName}/admin/test-error" page={TestErrorPage} name="testError" />
          </Set>
          {/* Onboarding */}
          <Set wrap={OnboardingLayout}>
            <Route path="/{orgName}/onboarding/save-archive" page={OnboardingSaveArchivePage} name="onboardingSaveArchive" />
            <Route path="/{orgName}/onboarding/import-preview" page={OnboardingBulkImportDocumentPreviewPage} name="onboardingBulkImportDocumentPreview" />
            <Route path="/{orgName}/onboarding/v2" page={OnboardingDocumentMigrationV2Page} name="onboardingDocumentMigrationV2" />
          </Set>
          <Set wrap={[ProductsLayout, ProductsContext]}>
            <Route path="/{orgName}" page={HomePage} name="home" />
            {/* Products */}
            <Route path="/{orgName}/products" page={ProductsPage} name="products" />
            <Set wrap={ProductLayout}>
              <Route path="/{orgName}/products/{productId:Int}" page={ProductPage} name="product" />
              {/* Design Controls */}
              <Route path="/{orgName}/products/{productId:Int}/design-control/{designControlId:Int}/configure" page={DesignControlsConfigurePage} name="designControlsConfigure" />
              <Route path="/{orgName}/products/{productId:Int}/design-control/{designControlId:Int}/list" page={DesignControlsListPage} name="designControlsList" />
              <Route path="/{orgName}/products/{productId:Int}/design-control/{designControlId:Int}" page={DesignControlPage} name="designControl" />
              <Route path="/{orgName}/products/{productId:Int}/design-control/{designControlId:Int}/components" page={ComponentsPage} name="components" />
              <Route path="/{orgName}/products/{productId:Int}/design-control/{designControlId:Int}/trace" page={TraceabilityMatrixPage} name="traceabilityMatrix" />
              <Route path="/{orgName}/products/{productId:Int}/design-control/{designControlId:Int}/scratchpad" page={DesignControlsScratchpadPage} name="scratchpad" />
              <Route path="/{orgName}/products/{productId:Int}/design-control/{designControlId:Int}/custom-product-document-test" page={CustomProductDocumentTestPage} name="customProductDocumentTest" />
              {/*
                keep this as a legacy route post-renaming of reviews -> [product] reviews to support old links
              */}
              <Route path="/{orgName}/products/{productId:Int}/design-control/{designControlId:Int}/design-review/new" page={NewDesignReviewPage} name="_legacyNewDesignReview" />
              <Route path="/{orgName}/products/{productId:Int}/design-control/{designControlId:Int}/product-review/new" page={NewDesignReviewPage} name="newDesignReview" />

              {/* Risks (FMEA Sheets) */}
              <Route path="/{orgName}/products/{productId:Int}/risks" page={RiskSheetsPage} name="risks" />

              <Route path="/{orgName}/products/{productId:Int}/risks/iso14971/ram/new" page={NewRiskAcceptabilityMatrixPage} name="newRiskAcceptabilityMatrix" />
              <Route path="/{orgName}/products/{productId:Int}/risks/iso14971/ram/{matrixId:Int}/edit" page={EditRiskAcceptabilityMatrixPage} name="editRiskAcceptabilityMatrix" />
              <Set wrap={RiskManagementContext}>
                <Route path="/{orgName}/products/{productId:Int}/risks/iso14971/ram/{matrixId:Int}" page={RiskAcceptabilityMatrixPage} name="riskAcceptabilityMatrix" />
                <Route path="/{orgName}/products/{productId:Int}/risks/iso14971/risk-config" page={RiskConfigurationPage} name="riskConfig" />
                <Route path="/{orgName}/products/{productId:Int}/risks/iso14971/harms" page={HarmsPage} name="harms" />
                <Route path="/{orgName}/products/{productId:Int}/risks/iso14971/harms/{harmId:Int}" page={HarmsPage} name="harm" />
                <Route path="/{orgName}/products/{productId:Int}/risks/iso14971/harms/{harmId:Int}/versions/{harmVersionId:Int}" page={HarmsPage} name="harmVersion" />
                <Route path="/{orgName}/products/{productId:Int}/risks/iso14971" page={RisksPage} name="iso14971Risks" />
                <Route path="/{orgName}/products/{productId:Int}/risks/iso14971/{riskId:Int}" page={RisksPage} name="iso14971Risk" />
                <Route path="/{orgName}/products/{productId:Int}/risks/iso14971/{riskId:Int}/versions/{riskVersionId:Int}" page={RisksPage} name="iso14971RiskVersion" />
              </Set>
            </Set>
          </Set>
          {/* Change Orders */}
          <Set wrap={ChangeLayout}>
            <Route path="/{orgName}/change-orders" page={ChangeOrdersPage} name="changeOrders" />
            <Route path="/{orgName}/change-orders/new" page={NewChangeOrderPage} name="newChangeOrder" />
            <Route path="/{orgName}/change-orders/{changeOrderId:Int}" page={ChangeOrderPage} name="changeOrder" />
            <Route path="/{orgName}/change-orders/types" page={ChangeOrderTypesPage} name="changeOrderTypes" />
            <Route path="/{orgName}/change-orders/types/new" page={NewChangeOrderTypePage} name="newChangeOrderType" />
            <Route path="/{orgName}/change-orders/types/{changeOrderTypeId:Int}" page={ChangeOrderTypePage} name="changeOrderType" />
            <Route path="/{orgName}/change-orders/types/{changeOrderTypeId:Int}/edit" page={EditChangeOrderTypePage} name="editChangeOrderType" />
          </Set>
          {/* Quality Events */}
          <Set wrap={QualityEventsLayout}>
            <Route path="/{orgName}/quality-events/types" page={QualityEventTypesPage} name="qualityEventTypes" />
            <Route path="/{orgName}/quality-events/types/new" page={NewQualityEventTypePage} name="newQualityEventType" />
            <Route path="/{orgName}/quality-events/types/{qualityEventTypeId:Int}" page={QualityEventTypePage} name="qualityEventType" />
            <Route path="/{orgName}/quality-events/types/{qualityEventTypeId:Int}/edit" page={EditQualityEventTypePage} name="editQualityEventType" />
            <Route path="/{orgName}/quality-events" page={QualityEventsPage} name="qualityEvents" />
            <Route path="/{orgName}/quality-events/{qualityEventId:Int}" page={QualityEventPage} name="qualityEvent" />
            <Route path="/{orgName}/quality-events/new" page={NewQualityEventPage} name="newQualityEvent" />
          </Set>
          {/* Documents */}
          <Set wrap={DocumentsLayout}>
            <Route path="/{orgName}/documents" page={DocumentsPage} name="documents" />
            <Route path="/{orgName}/documents/document-types" page={DocumentTypesPage} name="documentTypes" />
            <Route path="/{orgName}/documents/new" page={NewDocumentPage} name="newDocument" />
            <Route path="/{orgName}/documents/{id:Int}/edit" page={EditDocumentPage} name="editDocument" />
            <Route path="/{orgName}/documents/{id:Int}/feedback" page={DocumentFeedbackPage} name="documentFeedback" />

            <Route path="/{orgName}/documents/{documentId:Int}/view" page={DocumentComparePage} name="documentView" />
            <Route path="/{orgName}/documents/{documentId:Int}/view/{before:Int}...{after:Int}" page={DocumentComparePage} name="documentCompare" />
          </Set>
          {/* Training */}
          <Set wrap={TrainingLayout}>
            <Route path="/{orgName}/training/assignments/{trainingAssignmentId:Int}" page={TrainingAssignmentPage} name="trainingAssignment" />
            <Route path="/{orgName}/training/overview" page={TrainingPage} name="trainingOverview" />
            <Route path="/{orgName}/training/completion-rates" page={TrainingPage} name="trainingCompletionRates" />
            <Route path="/{orgName}/training/policies" page={TrainingPoliciesPage} name="trainingPolicies" />
            <Route path="/{orgName}/training/policies/new" page={NewTrainingPolicyPage} name="newTrainingPolicy" />
            <Route path="/{orgName}/training/policies/{trainingPolicyId:Int}" page={TrainingPolicyPage} name="trainingPolicy" />
            <Route path="/{orgName}/training/policies/{trainingPolicyId:Int}/edit" page={EditTrainingPolicyPage} name="editTrainingPolicy" />
          </Set>
          {/* Supplier Management */}
          <Set wrap={SupplierLayout}>
            <Route path="/{orgName}/suppliers" page={SuppliersPage} name="suppliers" />
            <Route path="/{orgName}/suppliers/configure" page={SupplierConfigPage} name="supplierConfig" />
            <Route path="/{orgName}/suppliers/new" page={NewSupplierPage} name="newSupplier" />
            <Route path="/{orgName}/suppliers/{supplierId:Int}/edit" page={EditSupplierPage} name="editSupplier" />
          </Set>
          {/* Parts */}
          <Set wrap={PartsLayout}>
            <Route path="/{orgName}/parts" page={PartsPage} name="parts" />
            <Route path="/{orgName}/parts/types" page={PartTypesPage} name="partTypes" />
            <Route path="/{orgName}/parts/{partId:Int}" page={PartPage} name="part" />
          </Set>
          {/* Compliance */}
          <Set wrap={ComplianceLayout}>
            <Route path="/{orgName}/compliance" page={CompliancePage} name="compliance" />
            <Route path="/{orgName}/compliance/frameworks" page={FrameworksPage} name="frameworks" />
            <Route path="/{orgName}/compliance/frameworks/{frameworkId:Int}" page={FrameworkPage} name="framework" />
            <Route path="/{orgName}/compliance/requirements" page={FrameworkRequirementsPage} name="frameworkRequirements" />
          </Set>
          <Set wrap={SbomLayout}>
            <Route path="/{orgName}/sboms" page={SbomsPage} name="sboms" />
            <Route path="/{orgName}/sboms/repositories" page={SbomRepositoryPage} name="sbomRepositories" />
            <Route path="/{orgName}/sboms/{sbomId:Int}" page={SbomPage} name="sbom" />
          </Set>
          <Set wrap={InventoryLayout}>
            <Route path="/{orgName}/inventory" page={InventoryPage} name="inventory" />
            <Route path="/{orgName}/inventory/receiving" page={ReceivingRecordsPage} name="inventoryReceivingRecords" />
            <Route path="/{orgName}/inventory/receiving/new" page={ReceivingRecordPage} name="newInventoryReceivingRecord" />
            <Route path="/{orgName}/inventory/receiving/{receivingRecordId:Int}" page={ReceivingRecordPage} name="inventoryReceivingRecord" />
          </Set>
          {/* Audit Trail */}
          <Route path="/{orgName}/audit-trail" page={AuditTrailPage} name="auditTrail" />
          {/* Redirects */}
          <Route path="/{orgName}/redirects/document-versions/{resourceId:Int}" page={DocumentVersionRedirectPage} name="documentVersionRedirect" />
          <Route path="/{orgName}/redirects/harm-versions/{resourceId:Int}" page={HarmVersionRedirectPage} name="harmVersionRedirect" />
          <Route path="/{orgName}/redirects/harms/{resourceId:Int}" page={HarmRedirectPage} name="harmRedirect" />
          <Route path="/{orgName}/redirects/risk-versions/{resourceId:Int}" page={RiskVersionRedirectPage} name="riskVersionRedirect" />
          <Route path="/{orgName}/redirects/risks/{resourceId:Int}" page={RiskRedirectPage} name="riskRedirect" />
          <Route path="/{orgName}/redirects/design-element-versions/{resourceId:Int}" page={DesignElementVersionRedirectPage} name="designElementVersionRedirect" />
          <Route path="/{orgName}/redirects/user-need-versions/{resourceId:Int}" page={UserNeedVersionRedirectPage} name="userNeedVersionRedirect" />
          <Route path="/{orgName}/redirects/system-requirement-versions/{resourceId:Int}" page={SystemRequirementVersionRedirectPage} name="systemRequirementVersionRedirect" />
          <Route path="/{orgName}/redirects/subsystem-requirement-versions/{resourceId:Int}" page={SubsystemRequirementVersionRedirectPage} name="subsystemRequirementVersionRedirect" />
          <Route path="/{orgName}/redirects/design-output-versions/{resourceId:Int}" page={DesignOutputVersionRedirectPage} name="designOutputVersionRedirect" />
          <Route path="/{orgName}/redirects/verification-versions/{resourceId:Int}" page={VerificationVersionRedirectPage} name="verificationVersionRedirect" />
          <Route path="/{orgName}/redirects/validation-versions/{resourceId:Int}" page={ValidationVersionRedirectPage} name="validationVersionRedirect" />
          <Route path="/{orgName}/redirects/file-download/{fileId:Int}" page={FileDownloadRedirectPage} name="fileDownloadRedirect" /> {/* used by design element spec file imports */}
          {/*
            keep this as a legacy route post-renaming of reviews -> [product] reviews to support old links
          */}
          <Route path="/{orgName}/redirects/design-reviews/{resourceId:Int}" page={DesignReviewRedirectPage} name="designReviewRedirect" />
          <Route path="/{orgName}/redirects/product-reviews/{resourceId:Int}" page={DesignReviewRedirectPage} name="productReviewRedirect" />
          <Route path="/{orgName}/redirects/training-policies/{resourceId:Int}" page={TrainingPolicyVersionRedirectPage} name="trainingPolicyVersionRedirect" />
          <Route path="/{orgName}/redirects/supplier-record-versions/{resourceId:Int}" page={SupplierRecordVersionRedirectPage} name="supplierRecordVersionRedirect" />
          <Route path="/{orgName}/redirects/change-orders/{resourceId:Int}" page={ChangeOrderRedirectPage} name="changeOrderRedirect" />
          <Route path="/{orgName}/redirects/change-order-type-versions/{resourceId:Int}" page={ChangeOrderTypeVersionRedirectPage} name="changeOrderTypeVersionRedirect" />
          <Route path="/{orgName}/redirects/change-order-tasks/{resourceId:Int}" page={ChangeOrderTaskRedirectPage} name="changeOrderTaskRedirect" />
          <Route path="/{orgName}/redirects/quality-events/{resourceId:Int}" page={QualityEventRedirectPage} name="qualityEventRedirect" />
          <Route path="/{orgName}/redirects/quality-event-type-versions/{resourceId:Int}" page={QualityEventTypeVersionRedirectPage} name="qualityEventTypeVersionRedirect" />
          <Route path="/{orgName}/redirects/quality-event-tasks/{resourceId:Int}" page={QualityEventTaskRedirectPage} name="qualityEventTaskRedirect" />
          <Route path="/{orgName}/redirects/parts/{resourceId:Int}" page={PartRedirectPage} name="partRedirect" />
          <Route path="/{orgName}/redirects/part-versions/{resourceId:Int}" page={PartVersionRedirectPage} name="partVersionRedirect" />
          {/* WARNING: Do not change these routes without implementing a legacy redirect.

            They are hardcoded in our notification emails:
            - designReviewRequestEmailJob.ts
            - documentReviewRequestEmailJob.ts
            - documentApprovalRequestEmailJob.ts
            - newTrainingEmailJob
            - overdueTrainingEmailJob.ts
            - supplierAssessmentsEmailJob.ts
            - task*EmailJob.ts

            As of March 2023, redwood does not support usage of @redwoodjs/router on the api side
            */}
          <Route path="/{orgName}/documents/{id:Int}" page={DocumentPage} name="document" />
          <Set wrap={[ProductsContext, ProductLayout, RiskManagementContext]}>
            {/*
              keep this as a legacy route post-renaming of reviews -> [product] reviews to support old links
            */}
            <Route path="/{orgName}/products/{productId:Int}/design-control/{designControlId:Int}/design-reviews/{designReviewId:Int}" page={DesignReviewPage} name="_legacyDesignReview" />
            <Route path="/{orgName}/products/{productId:Int}/design-control/{designControlId:Int}/reviews/{designReviewId:Int}" page={DesignReviewPage} name="designReview" />
          </Set>
          <Route path="/{orgName}/training/assignments" page={TrainingPage} name="trainingAssignments" />
          <Route path="/{orgName}/suppliers/{supplierId:Int}" page={SupplierPage} name="supplier" />
          <Route path="/{orgName}/tasks" page={TasksPage} name="tasks" />
          {/* END WARNING */}
        </Set>

        <Set wrap={[AuthValidator, MainLayout.RightFabs]}>
          <Set wrap={[ProductsLayout, ProductsContext]}>
            <Set wrap={ProductLayout}>
              {/* Risks (FMEA Sheets) */}
              <Route path="/{orgName}/products/{productId:Int}/risks/fmea/{riskSheetId:Int}" page={RiskSheetPage} name="riskSheet" />
            </Set>
          </Set>
        </Set>

        <Set wrap={[AuthValidator, SettingsLayout]}>
          <Route path="/{orgName}/settings/users" page={UserManagementPage} name="userManagement" />
          <Route path="/{orgName}/settings/documentTypes" page={DocumentTypesPage} name="settingsDocumentTypes" />
          <Route path="/{orgName}/settings/user-groups" page={UserGroupsPage} name="userGroups" />
          <Route path="/{orgName}/settings/training" page={TrainingSettingsPage} name="trainingSettings" />
          <Route path="/{orgName}/settings/ai" page={AiSettingsPage} name="aiSettings" />
          <Route path="/{orgName}/settings/api-key" page={ApiKeySettingsPage} name="apiKeySettings" />
          <Route path="/{orgName}/settings/import-export" page={ImportExportPage} name="importExport" />
          <Route path="/{orgName}/settings/integrations" page={TicketingIntegrationsPage} name="integrations" />
        </Set>
        <Route path="/{orgName}/permissions" page={PermissionsPage} name="permissions" />
      </PrivateSet>
      <Route path="/user/logout" page={LogoutPage} name="logout" />
      <Route path="/user/login" page={LoginPage} name="login" />
      <Route path="/user/unauthorized-organization" page={UnauthorizedOrganizationPage} name="unauthorizedOrganization" />
      <Route path="/user/reset-password" page={ResetPasswordPage} name="resetPassword" />

      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
