import { datadogRum } from '@datadog/browser-rum'
import { CssBaseline, GlobalStyles } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-license'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { Provider as ReduxProvider } from 'react-redux'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import heap from 'src/lib/analytics/heap'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import store from 'src/redux/store'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'
import Analytics from './components/Analytics'
import DarkModeContext, { useDarkMode } from './contexts/DarkModeContext'
import logger from './lib/logger'
import themeOverrides from './themeOverrides'
import './fonts/inter/inter.css'
import './scaffold.css'

dayjs.extend(duration)
dayjs.extend(timezone)
dayjs.extend(utc)

heap()
window.addEventListener('vite:preloadError', (_event) => {
  logger.warn('Vite preload error')
  window.location.reload()
})

declare global {
  interface Window {
    localStorage: {
      getItem: (key: string) => string
    }
    Intercom: (command: string, options?: unknown) => void
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  module NodeJS {
    interface ProcessEnv {
      DATADOG_ENV: string
      DATADOG_APP_ID: string
      DATADOG_CLIENT_TOKEN: string
      DD_VERSION?: string // git commit hash, set by Coherence in our deployed environments
      AUTH0_CLIENT_ID: string
      AUTH0_AUDIENCE: string
      AUTH0_SCOPE: string
      AUTH0_DOMAIN: string
      LAUNCHDARKLY_CLIENT_ID: string
      MUI_LICENSE_KEY: string
      USECSV_IMPORTER_KEY: string
    }
  }
}

datadogRum.init({
  applicationId: process.env.DATADOG_APP_ID,
  clientToken: process.env.DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'ultralight',
  env: process.env.DATADOG_ENV,
  version: process.env.DD_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
})
datadogRum.startSessionReplayRecording()

LicenseInfo.setLicenseKey(process.env.MUI_LICENSE_KEY)

const App = () => {
  const { darkMode, darkModeToggler } = useDarkMode()
  const theme = React.useMemo(() => themeOverrides(darkMode), [darkMode])

  return (
    <FatalErrorBoundary page={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <ReduxProvider store={store}>
            <Analytics />
            <RedwoodApolloProvider
              useAuth={useAuth}
              graphQLClientConfig={{
                cacheConfig: {
                  typePolicies: {
                    FocusDesignElement: {
                      keyFields: ['resourceKey'],
                    },
                  },
                },
              }}
            >
              <DarkModeContext.Provider value={darkModeToggler}>
                {/* Any additional theming or baseline styles added here should be duplicated in web/config/storybook.preview.js for consistency */}
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <GlobalStyles
                    styles={{
                      a: {
                        color: 'inherit',
                        textDecoration: 'none',
                      },
                    }}
                  />
                  <Routes />
                </ThemeProvider>
              </DarkModeContext.Provider>
            </RedwoodApolloProvider>
          </ReduxProvider>
        </AuthProvider>
      </RedwoodProvider>
    </FatalErrorBoundary>
  )
}

export default withLDProvider({
  clientSideID: process.env.LAUNCHDARKLY_CLIENT_ID,
  context: {
    key: 'anonymous',
  },
})(App)
