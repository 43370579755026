import { Typography } from '@mui/material'

import { IconChevronLeft } from '../Icon'

const BackLink: React.FC = () => {
  return (
    <Typography
      fontWeight="medium"
      color="text.secondary"
      sx={({ palette }) => ({
        cursor: 'pointer',
        lineHeight: '24px',
        '& svg': {
          height: '10px',
          mr: '15px',
          '& > path': {
            stroke: palette.text.secondary,
          },
        },
      })}
      data-testid="header-backlink"
    >
      <IconChevronLeft data-testid="breadcrumb-back" />
      Back
    </Typography>
  )
}

export default BackLink
