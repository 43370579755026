import { configureStore } from '@reduxjs/toolkit'

import userGroupsReducer from './features/userGroupsSlice'

const store = configureStore({
  reducer: {
    userGroups: userGroupsReducer,
  },
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
