import { useAuth } from 'src/auth'

import logger from '../logger'

declare global {
  interface Window {
    heap?: {
      load: (appId: string) => void

      // in the case a user blocks user tracking, these methods should fail gracefully
      identify?: (uid: string) => void
      addUserProperties?: (properties: Record<string, string>) => void
    }
  }
}

/* eslint-disable */
const heap = () => {
  try {
    if (process.env.HEAP_APP_ID) {
      // @ts-ignore
      window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
    }
  } catch (err) {
    logger.error(err, 'Heap initialization error')
  }
}
/* eslint-enable */

export const useHeapIdentify = () => {
  const { currentUser } = useAuth()

  React.useEffect(() => {
    try {
      if (currentUser && process.env.HEAP_APP_ID) {
        // filter out internal users
        if (
          currentUser.organizationUsers.some(
            (ou) =>
              ou.organization.name === 'ultralight' ||
              ou.organization.name === 'ultralight-test'
          )
        ) {
          return
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        heap.load(process.env.HEAP_APP_ID)

        window.heap?.identify?.(
          String(
            currentUser.authUserId_DO_NOT_USE_UNLESS_YOU_KNOW_WHAT_YOURE_DOING
          )
        )

        window.heap?.addUserProperties?.({
          Email: currentUser.email,
          'Organization IDs': currentUser.organizationUsers
            .map((ou) => ou.organization.id)
            .join(', '),
          'Organization Names': currentUser.organizationUsers
            .map((ou) => ou.organization.longName)
            .join(', '),
        })
      }
    } catch (err) {
      logger.error(err, 'Failed to push heap user metadata')
    }
  }, [currentUser])
}

export default heap
