import type { SVGProps } from 'react'
const IconIntegration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.7971 12.039L11.9608 4.20264C11.3661 3.60795 10.3646 3.76428 9.97947 4.51193L5.48242 13.2415L10.7583 18.5174L19.4878 14.0203C20.2355 13.6352 20.3918 12.6337 19.7971 12.039Z"
      stroke="#333333"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 20.9999L7.65517 16.3447"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1719 6.10345L17.2753 3"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8965 9.82757L20.9999 6.72412"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default IconIntegration
