import {
  TaskPermissions,
  TaskPermissionsQuery,
  TaskPermissionsQueryVariables,
  TaskStatus,
  User,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { getCurrentOrgUser } from 'src/lib/currentUserUtils'
import uiStorage from 'src/lib/localStorage/uiStorage'

const QUERY = gql`
  query TaskPermissionsQuery {
    permissions {
      task {
        read
        readOverview
        write
        closeAsAdmin
      }
    }
  }
`

const useTaskPermissions = () => {
  const { loading, data } = useQuery<
    TaskPermissionsQuery,
    TaskPermissionsQueryVariables
  >(QUERY)

  React.useEffect(() => {
    if (data) {
      uiStorage.cachedPermissions.set(data.permissions)
    }
  }, [data])

  const cachedPermissions = uiStorage.cachedPermissions.get()

  return {
    loading,
    taskPermissions: cachedPermissions?.task ?? null,
  }
}

type MaybeTaskPermissions = TaskPermissions | null

export const useTaskActions = () => {
  const { taskPermissions } = useTaskPermissions()
  return {
    canSeeTasks: () => canSeeTasks(taskPermissions),
    canSeeTaskOverview: () => canSeeTaskOverview(taskPermissions),
    canEditTask: (status: TaskStatus) => canEditTask(taskPermissions, status),
    canCloseTask: (
      status: TaskStatus,
      createdByUser?: Pick<User, 'id'> | null,
      assignee?: Pick<User, 'id'> | null
    ) => canCloseTask(taskPermissions, status, createdByUser, assignee),
  }
}

const canSeeTasks = (taskPermissions: MaybeTaskPermissions): boolean => {
  if (!taskPermissions?.read) {
    return false
  }
  return true
}

const canSeeTaskOverview = (taskPermissions: MaybeTaskPermissions): boolean => {
  if (!taskPermissions?.readOverview) {
    return false
  }
  return true
}

const canEditTask = (
  taskPermissions: MaybeTaskPermissions,
  status: TaskStatus
): boolean => {
  if (!taskPermissions?.write) {
    return false
  }

  if (status === 'CANCELLED' || status === 'CLOSED') {
    return false
  }

  return true
}

const canCloseTask = (
  taskPermissions: MaybeTaskPermissions,
  status: TaskStatus,
  createdByUser?: Pick<User, 'id'> | null,
  assignee?: Pick<User, 'id'> | null
): boolean => {
  const orgUser = getCurrentOrgUser()

  if (!taskPermissions?.write) {
    return false
  }

  if (status === 'CANCELLED' || status === 'CLOSED') {
    return false
  }

  const currentUserIsCreator = createdByUser?.id === orgUser?.id
  const currentUserIsAssignee = assignee?.id === orgUser?.id
  if (
    !taskPermissions?.closeAsAdmin &&
    !currentUserIsCreator &&
    !currentUserIsAssignee
  ) {
    return false
  }

  return true
}
