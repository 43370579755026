import * as React from 'react'

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from '@mui/material'

import { Link, useLocation, useParams } from '@redwoodjs/router'
import { routes } from '@redwoodjs/router'

import {
  IconChevronLeft,
  IconGraduationHat,
  IconIntegration,
  IconUserList,
  IconUsers,
  IconDownload,
  IconCode,
} from 'src/components/Icon'
import useFeature from 'src/hooks/useFeature'
import IconCopilotSVG from 'src/images/icons/IconCopilotSVG'
import useNavStore from 'src/stores/useNavStore'

import BackLink from '../Backlink/Backlink'
import DrawerToggleButton from '../DrawerToggleButton/DrawerToggleButton'
import NavBox from '../NavBox/NavBox'

type NavItem = {
  linkTo: string
  text: string
  icon: React.ReactNode
  isSelected: boolean
  enabled: boolean
}

const SettingsNav: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    trainingEnabled,
    productsEnabled,
    importExportEnabled,
    testAutomationEnabled,
  } = useFeature()
  const { navWidth, open } = useNavStore()
  const { palette } = useTheme()
  const { pathname } = useLocation()
  const { orgName } = useParams()

  const upperNavItems = React.useMemo<NavItem[]>(
    () =>
      [
        {
          linkTo: routes.userManagement({ orgName }),
          text: 'Users',
          icon: <IconUserList />,
          isSelected: pathname === routes.userManagement({ orgName }),
          enabled: true,
        },
        {
          linkTo: routes.userGroups({ orgName }),
          text: 'Groups',
          icon: <IconUsers />,
          isSelected: pathname === routes.userGroups({ orgName }),
          enabled: trainingEnabled,
        },
        {
          linkTo: routes.trainingSettings({ orgName }),
          text: 'Training',
          icon: <IconGraduationHat />,
          isSelected: pathname === routes.trainingSettings({ orgName }),
          enabled: trainingEnabled,
        },
        {
          linkTo: routes.aiSettings({ orgName }),
          text: 'AI',
          icon: <IconCopilotSVG />,
          isSelected: pathname === routes.aiSettings({ orgName }),
          enabled: true,
        },
        {
          linkTo: routes.integrations({ orgName }),
          text: 'Integrations',
          icon: <IconIntegration />,
          isSelected: pathname === routes.integrations({ orgName }),
          enabled: productsEnabled,
        },
        {
          linkTo: routes.importExport({ orgName }),
          text: 'Import / Export',
          icon: <IconDownload />,
          isSelected: pathname === routes.importExport({ orgName }),
          enabled: importExportEnabled,
        },
        {
          linkTo: routes.apiKeySettings({ orgName }),
          text: 'API Keys',
          icon: <IconCode />,
          isSelected: pathname === routes.apiKeySettings({ orgName }),
          enabled: testAutomationEnabled,
        },
      ].filter(({ enabled }) => enabled),
    [
      orgName,
      pathname,
      trainingEnabled,
      productsEnabled,
      importExportEnabled,
      testAutomationEnabled,
    ]
  )

  const drawer = (
    <NavBox>
      <List sx={{ flex: 1 }}>
        <ListItem sx={{ padding: '18px 24px' }}>
          <Link to={routes.home({ orgName })}>
            {open ? (
              <BackLink />
            ) : (
              <IconChevronLeft color={palette.text.secondary} />
            )}
          </Link>
        </ListItem>
        {upperNavItems.map(({ linkTo, text, icon, isSelected }) => (
          <Link to={linkTo} key={text}>
            <Tooltip placement="right" title={open ? null : text}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={isSelected}
                  sx={{ paddingLeft: '28px' }}
                >
                  <ListItemIcon
                    sx={({ palette }) => ({ color: palette.text.primary })}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={text} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </Link>
        ))}
      </List>
    </NavBox>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{ width: { sm: navWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="permanent"
          anchor="left"
          sx={({ palette, typography, transitions }) => ({
            display: { xs: 'none', sm: 'block' },

            '& .MuiDrawer-paper': {
              boxShadow: 'none',
              boxSizing: 'border-box',
              width: navWidth,
              height: '100%',
              top: 0,
              borderRadius: 0,
              transition: transitions.create('width', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.standard,
              }),
              overflowX: 'hidden',
            },
            '& .MuiListItem--root': {
              height: '40px',
            },

            '& .MuiListItemButton-root': {
              marginLeft: '2px',

              '& .MuiListItemIcon-root': {
                minWidth: '30px',
              },

              '&.Mui-selected': {
                marginLeft: 'unset',
                borderLeft: `2px solid ${palette.primary.main}`,
              },
            },

            '& .MuiListItemText-primary': {
              fontSize: typography.fontSize,
            },
          })}
          open
        >
          {drawer}
        </Drawer>
        <DrawerToggleButton />
      </Box>
      <Box
        component="main"
        sx={{
          minHeight: '100vh',
          width: { sm: `calc(100% - ${navWidth}px)` },
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default SettingsNav
