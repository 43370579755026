import { DesignElementType } from 'types/graphql'
import { create } from 'zustand'

import {
  DesignElementTypeQueryParam,
  useDesignElementSearchParams,
} from 'src/hooks/useDesignElementSearchParams'

export type Tab = 'DETAILS' | 'LINKS' | 'INTEGRATIONS'

export type CreatingDeDefaults = {
  designElementType: DesignElementType
  componentId?: number
  subcomponentId?: number
  categoryId?: number
}

interface DesignControlState {
  tab: Tab
  setTab: (tab: Tab) => void

  creatingDeDefaults: CreatingDeDefaults | null
  setCreatingDeDefaults: (creatingDeDefaults: CreatingDeDefaults | null) => void

  isSidePanelFormDirty: boolean
  setSidePanelFormDirty: (isDirty: boolean) => void

  handleClearSelection: (e: React.MouseEvent) => boolean

  focusViewOpen: boolean
  setFocusViewOpen: (focusViewOpen: boolean) => void
}

const useDesignControlStore = create<DesignControlState>()((set, get) => ({
  tab: 'DETAILS',
  setTab: (tab) => set({ tab }),

  creatingDeDefaults: null,
  setCreatingDeDefaults: (creatingDeDefaults) => set({ creatingDeDefaults }),

  isSidePanelFormDirty: false,
  setSidePanelFormDirty: (isDirty) => set({ isSidePanelFormDirty: isDirty }),

  handleClearSelection: (e) => {
    if (
      !get().isSidePanelFormDirty ||
      window.confirm('Discard your unsaved changes?')
    ) {
      set({
        isSidePanelFormDirty: false,
        creatingDeDefaults: null,
      })
      return true
    } else {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
  },

  focusViewOpen: false,
  setFocusViewOpen: (focusViewOpen) => set({ focusViewOpen }),
}))

export const getDesignElementTypeQueryParam = (
  type: DesignElementType | null
): DesignElementTypeQueryParam | null => {
  switch (type) {
    case 'USER_NEED':
      return 'un'
    case 'SYSTEM_REQUIREMENT':
      return 'sr'
    case 'SUBSYSTEM_REQUIREMENT':
      return 'ssr'
    case 'DESIGN_OUTPUT':
      return 'do'
    case 'VERIFICATION':
      return 'ver'
    case 'VALIDATION':
      return 'val'
    case null:
      return null
  }
}

const getDesignElementType = (
  designElementQueryParam: string | null
): DesignElementType | null => {
  switch (designElementQueryParam) {
    case 'un':
      return 'USER_NEED'
    case 'sr':
      return 'SYSTEM_REQUIREMENT'
    case 'ssr':
      return 'SUBSYSTEM_REQUIREMENT'
    case 'do':
      return 'DESIGN_OUTPUT'
    case 'ver':
      return 'VERIFICATION'
    case 'val':
      return 'VALIDATION'
    default:
      return null
  }
}

export const useDesignControlState = () => {
  const { params, set, clear } = useDesignElementSearchParams()
  const {
    tab,
    setTab,
    creatingDeDefaults,
    setCreatingDeDefaults,
    isSidePanelFormDirty,
    setSidePanelFormDirty,
    handleClearSelection,
    focusViewOpen,
    setFocusViewOpen,
  } = useDesignControlStore()

  return {
    tab,
    setTab,
    selectedDesignElementType: getDesignElementType(params.type),
    selectedDesignElementIds: params.selectedIds,
    setSelectedDesignElements: (
      selectedDesignElementType: DesignElementType | null,
      selectedDesignElementIds: number[] | null
    ) => {
      set({
        type: getDesignElementTypeQueryParam(selectedDesignElementType),
        selectedIds: selectedDesignElementIds,
        versionId: null,
      })
      setCreatingDeDefaults(null)
    },
    creatingDeDefaults,
    setCreatingDeDefaults: (creatingDeDefaults: CreatingDeDefaults | null) => {
      setCreatingDeDefaults(creatingDeDefaults)
    },
    selectedVersionId: params.versionId,
    setSelectedVersionId: (selectedVersionId: number | null) =>
      set({
        versionId: selectedVersionId,
      }),
    isSidePanelFormDirty,
    setSidePanelFormDirty,
    handleClearSelection: (e: React.MouseEvent) => {
      handleClearSelection(e) && clear()
    },
    focusViewOpen,
    setFocusViewOpen,
  }
}

export default useDesignControlState
