import useFeature from 'src/hooks/useFeature'

import { handleNotFound } from '../layoutUtils'

type DocumentLayoutProps = {
  children?: React.ReactNode
}

const DocumentLayout = ({ children }: DocumentLayoutProps) => {
  const { documentsEnabled } = useFeature()
  if (!documentsEnabled) {
    return handleNotFound()
  }
  return <>{children}</>
}

export default DocumentLayout
