import _ from 'lodash'

import { useAuth } from 'src/auth'

export default () => {
  const { currentUser } = useAuth()

  React.useEffect(() => {
    if (currentUser) {
      const firstOrgUser = _.sortBy(
        currentUser.organizationUsers,
        (orgUser) => orgUser.organization.id
      )[0]

      window.Intercom?.('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'a4fsgema',
        custom_launcher_selector: '#intercom-custom-launcher',

        // we want intercom users to map 1:1 with an actual person, so people don't get multiple messages per org
        user_id: currentUser.email,
        user_hash: firstOrgUser.userEmailHashForIntercom,
        email: currentUser.email,
        session_duration: 1000 * 60 * 60 * 8, // 8 hours
        name: `${firstOrgUser.firstName} ${firstOrgUser.lastName}`,
        organization_ids: currentUser.organizationUsers
          .map((orgUser) => orgUser.organization.id)
          .join(','),
        organization_names: currentUser.organizationUsers
          .map((orgUser) => orgUser.organization.name)
          .join(','),
      })
    }
  }, [currentUser])
}
