import { RiskManagementContextQuery, RiskProbabilityMode } from 'types/graphql'
import { create } from 'zustand'

type refetchFn = (() => void) | undefined | null

interface RiskManagementState {
  data: {
    matrixId: number | null
    probabilityMode: RiskProbabilityMode | null
    hasHarms: boolean
    hasRisks: boolean
  } | null
  setRiskManagementContext: ({
    product,
  }: {
    product: NonNullable<RiskManagementContextQuery['product']>
  }) => void

  refetchRiskManagementContext: refetchFn
  setRefetchRiskManagementContext: (
    refetchRiskManagementContext: refetchFn
  ) => void
}

const useRiskManagementStore = create<RiskManagementState>()((set) => ({
  data: null, // null when data is not loaded yet

  setRiskManagementContext: ({ product }) =>
    set({
      data: {
        matrixId: product?.riskMatrix?.id ?? null,
        probabilityMode: product?.riskMatrix?.probabilityMode ?? null,
        hasHarms: (product?.riskMatrix?.harms.length ?? 0) > 0,
        hasRisks: (product?.riskMatrix?.risks.length ?? 0) > 0,
      },
    }),

  refetchRiskManagementContext: null,

  setRefetchRiskManagementContext: (refetchRiskManagementContext) =>
    set({ refetchRiskManagementContext }),
}))

export default useRiskManagementStore
