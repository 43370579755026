export default gql`
  query TraceMatrixQuery($designControlId: Int!) {
    userNeeds: userNeedsForTraceMatrix(designControlId: $designControlId) {
      id
      resourceKey
      systemRequirements {
        id
        resourceKey

        subsystemRequirements {
          id
          resourceKey

          designOutputs {
            id
            resourceKey
          }
          verifications {
            id
            resourceKey
          }
        }
        designOutputs {
          id
          resourceKey
        }
        verifications {
          id
          resourceKey
        }
      }
      subsystemRequirements {
        id
        resourceKey

        designOutputs {
          id
          resourceKey
        }
        verifications {
          id
          resourceKey
        }
      }
      validations {
        id
        resourceKey
      }
    }

    risks: risksForTraceMatrix(designControlId: $designControlId) {
      id
      resourceKey
      systemRequirements {
        id
        resourceKey

        subsystemRequirements {
          id
          resourceKey

          designOutputs {
            id
            resourceKey
          }
          verifications {
            id
            resourceKey
          }
        }
        designOutputs {
          id
          resourceKey
        }
        verifications {
          id
          resourceKey
        }
      }
      subsystemRequirements {
        id
        resourceKey

        designOutputs {
          id
          resourceKey
        }
        verifications {
          id
          resourceKey
        }
      }
    }

    orphanSystemRequirements(designControlId: $designControlId) {
      id
      resourceKey

      subsystemRequirements {
        id
        resourceKey

        designOutputs {
          id
          resourceKey
        }
        verifications {
          id
          resourceKey
        }
      }
      designOutputs {
        id
        resourceKey
      }
      verifications {
        id
        resourceKey
      }
    }
    orphanSubsystemRequirements(designControlId: $designControlId) {
      id
      resourceKey

      designOutputs {
        id
        resourceKey
      }
      verifications {
        id
        resourceKey
      }
    }
    orphanDesignOutputs(designControlId: $designControlId) {
      id
      resourceKey
    }
    orphanVerifications(designControlId: $designControlId) {
      id
      resourceKey
    }
    orphanValidations(designControlId: $designControlId) {
      id
      resourceKey
    }
  }
`
