export const USERGROUP_DETAILS_FRAGMENT = gql`
  fragment UserGroupDetailsFragment on UserGroup {
    id
    name
    users {
      id
      name
      fullName
      initials
      status
      email
    }
  }
`

export default gql`
  ${USERGROUP_DETAILS_FRAGMENT}
  query UserGroupsQuery {
    userGroups {
      ...UserGroupDetailsFragment
    }
  }
`
