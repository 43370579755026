const getValue = () => {
  const clientId = process.env.AUTH0_CLIENT_ID
  const audience = process.env.AUTH0_AUDIENCE
  const scope = process.env.AUTH0_SCOPE

  return JSON.parse(
    window.localStorage.getItem(
      `@@auth0spajs@@::${clientId}::${audience}::${scope}`
    )
  )
}

const auth0Storage = {
  getAccessToken: (): string => getValue().body.access_token,
  isTokenExpired: (): boolean => {
    const nowSeconds = Date.now() / 1000
    return nowSeconds > Number(getValue().expiresAt)
  },
  isTokenAlmostExpired: (windowInSeconds: number): boolean => {
    const nowSeconds = Date.now() / 1000
    const expiresAt = Number(getValue().expiresAt)
    const diff = expiresAt - nowSeconds
    return diff < windowInSeconds
  },
}

export default auth0Storage
