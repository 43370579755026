import { ButtonProps } from '@mui/material'
import { create } from 'zustand'

type Message = string | React.ReactNode | null
type MessageOptions = {
  message: Message
  confirmButtonVariant?: ButtonProps['variant']
  confirmButtonText?: string
}

interface ConfirmationDialogStore {
  isOpen: boolean
  message: Message
  confirmButtonVariant?: ButtonProps['variant']
  confirmButtonText?: string
  action: (() => void | Promise<void>) | null

  open: (
    message: Message | MessageOptions,
    action: () => void | Promise<void>
  ) => void
  close: () => void
}

const useConfirmationDialogStore = create<ConfirmationDialogStore>()((set) => ({
  isOpen: false,
  message: null,
  action: null,

  open: (message, action) => {
    if (message && typeof message === 'object' && 'message' in message) {
      set({ isOpen: true, ...message, action })
    } else {
      set({ isOpen: true, message, confirmButtonVariant: 'contained', action })
    }
  },
  close: () => set({ isOpen: false, message: null, action: null }),
}))

export default useConfirmationDialogStore
