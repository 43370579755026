import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'

import useConfirmationDialogStore from 'src/stores/useConfirmationDialogStore'
import { defaultApolloErrorHandler } from 'src/utils'

const ConfirmationDialog: React.FC = () => {
  const {
    isOpen,
    message,
    confirmButtonVariant,
    confirmButtonText,
    action,
    close,
  } = useConfirmationDialogStore()

  const [loading, setLoading] = React.useState(false)

  return (
    <Dialog open={isOpen} onClose={close} maxWidth="xs">
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          Cancel
        </Button>
        <LoadingButton
          onClick={async () => {
            setLoading(true)
            try {
              await action?.()
              setLoading(false)
              close()
            } catch (err) {
              setLoading(false)
              defaultApolloErrorHandler(err as Error)
            }
          }}
          disabled={loading}
          loading={loading}
          variant={confirmButtonVariant}
        >
          {confirmButtonText ?? 'OK'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
