import {
  Menu,
  MenuItem,
  Typography,
  Stack,
  Divider,
  ListItemIcon,
  ListItemText,
  ToggleButtonGroup,
  ToggleButton,
  useTheme,
} from '@mui/material'
import { Organization, Role } from 'types/graphql'

import { routes, useParams, navigate } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import DarkModeContext from 'src/contexts/DarkModeContext'

import {
  IconLogOut,
  IconCheck,
  IconLinkExternal,
  IconSun,
  IconMoon,
} from '../Icon'
import UserRoleChip from '../UserRoleChip/UserRoleChip'

type AccountMenuProps = {
  anchorEl: null | HTMLElement
  setAnchorEl: (value: React.SetStateAction<null | HTMLElement>) => void
  open: boolean
  currentOrgUser: {
    firstName: string
    lastName: string
    role: Role
    organization: Organization
  }
}

const AccountMenu = ({
  anchorEl,
  setAnchorEl,
  open,
  currentOrgUser,
}: AccountMenuProps) => {
  const { currentUser } = useAuth()
  const { orgName } = useParams()
  const { palette } = useTheme()
  const darkModeToggler = React.useContext(DarkModeContext)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          minWidth: 260,
        },
      }}
    >
      <MenuItem sx={{ mt: 0.5 }} dense>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Stack>
            <Typography
              fontWeight={({ typography }) => typography.fontWeightMedium}
            >
              {currentOrgUser.firstName + ' ' + currentOrgUser.lastName}
            </Typography>
            <Typography
              fontSize={({ typography }) => typography.caption.fontSize}
            >
              {currentUser?.email}
            </Typography>
          </Stack>
          <UserRoleChip role={currentOrgUser.role} />
        </Stack>
      </MenuItem>
      <Divider />
      <MenuItem
        sx={{
          '&:hover': {
            backgroundColor: 'unset',
            cursor: 'unset',
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography fontWeight="medium">Theme</Typography>
          <ToggleButtonGroup value={palette.mode}>
            <ToggleButton
              value="light"
              onClick={darkModeToggler.disable}
              size="small"
            >
              <IconSun width="18" height="18" />
              <Typography>Light</Typography>
            </ToggleButton>
            <ToggleButton
              value="dark"
              onClick={darkModeToggler.enable}
              size="small"
            >
              <IconMoon width="18" height="18" />
              <Typography>Dark</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </MenuItem>
      <Divider />
      {currentUser?.organizationUsers.map((orgUser) => {
        const orgSelected = orgUser.organization.name === orgName

        return (
          <MenuItem
            key={orgUser.organization.id}
            selected={orgSelected}
            onClick={() => {
              navigate(routes.home({ orgName: orgUser.organization.name }))
              handleClose()
              window.location.reload()
            }}
            dense
            sx={{ height: 32, pl: '15px', pr: 0 }}
          >
            <Stack direction="row" justifyContent="space-between" width={1}>
              <Typography
                fontWeight={({ typography }) => typography.fontWeightMedium}
              >
                {orgUser.organization.longName}
              </Typography>
              {orgSelected && (
                <ListItemIcon
                  sx={({ palette }) => ({
                    color: palette.primary.main,
                  })}
                >
                  <IconCheck />
                </ListItemIcon>
              )}
            </Stack>
          </MenuItem>
        )
      })}
      <Divider />
      <MenuItem
        dense
        sx={{ height: 32 }}
        onClick={async () => {
          window.open(routes.changelog(), '_blank')
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width={1}
          alignItems="center"
        >
          <Typography>View Changelog</Typography>
          <IconLinkExternal height={14} width={14} />
        </Stack>
      </MenuItem>
      <Divider />
      <MenuItem
        dense
        sx={{ height: 32, mb: 0.5 }}
        onClick={async () => {
          navigate(routes.logout())
        }}
      >
        <ListItemIcon
          sx={({ palette }) => ({
            color: palette.text.secondary,
          })}
        >
          <IconLogOut />
        </ListItemIcon>
        <ListItemText
          sx={({ palette }) => ({
            '& .MuiListItemText-primary': {
              color: palette.text.secondary,
            },
          })}
        >
          Log out
        </ListItemText>
      </MenuItem>
    </Menu>
  )
}

export default AccountMenu
