const designReviewsQuery = gql`
  query DesignReviewsQuery($designControlId: Int!) {
    designReviews(designControlId: $designControlId) {
      id
      resourceKey
      title
      status
      createdAt
      generatedDesignReviewExports {
        generatedTraceabilityMatrixXlsxPreSignedUrl
        generatedRiskMatrixPreSignedUrl
      }
      approvedExports {
        name
        s3PreSignedUrl
        dhfFileType
      }
    }
  }
`

export default designReviewsQuery
