import {
  CreateDesignReviewMutation,
  CreateDesignReviewMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

const CREATE_DESIGN_REVIEW = gql`
  mutation CreateDesignReviewMutation($input: CreateDesignReviewInput!) {
    createDesignReview(input: $input)
  }
`

export type UseCreateDesignReviewMutationOptions = GraphQLMutationHookOptions<
  CreateDesignReviewMutation,
  CreateDesignReviewMutationVariables
>

const useCreateDesignReviewMutation = (
  options?: UseCreateDesignReviewMutationOptions
) => {
  const [createDesignReview, createDesignReviewResult] = useMutation<
    CreateDesignReviewMutation,
    CreateDesignReviewMutationVariables
  >(CREATE_DESIGN_REVIEW, {
    ...options,
  })

  return {
    createDesignReview,
    createDesignReviewResult,
  }
}

export default useCreateDesignReviewMutation
