import * as React from 'react'

import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material'

import { Link, routes, useLocation, useParams } from '@redwoodjs/router'

import {
  IconCheckSquareBroken,
  IconChevronDown,
  IconChevronUp,
  IconClockRefresh,
  IconCpuChip,
  IconCube,
  IconDocuments,
  IconFile,
  IconGitPullRequestSVG,
  IconGraduationHat,
  IconHome,
  IconList,
  IconSettings,
  IconTruck,
} from 'src/components/Icon'
import { useAuditActions } from 'src/hooks/useAuditActions'
import { useChangeOrderActions } from 'src/hooks/useChangeOrderActions'
import { useComplianceActions } from 'src/hooks/useComplianceActions'
import useFeature from 'src/hooks/useFeature'
import { useQualityEventActions } from 'src/hooks/useQualityEventActions'
import { useSbomActions } from 'src/hooks/useSbomActions'
import { useSettingsActions } from 'src/hooks/useSettingsActions'
import { useTaskActions } from 'src/hooks/useTaskActions'
import { useTrainingActions } from 'src/hooks/useTrainingActions'
import IconAward03 from 'src/images/icons/IconAward03SVG'
import logo from 'src/images/logo.png'
import { getCurrentOrgUser } from 'src/lib/currentUserUtils'
import useNavStore from 'src/stores/useNavStore'

import AccountMenu from '../AccountMenu/AccountMenu'
import DrawerToggleButton from '../DrawerToggleButton/DrawerToggleButton'
import NavBox from '../NavBox/NavBox'

type MainNavProps = {
  children?: React.ReactNode
}

const MainNav = ({ children }: MainNavProps) => {
  const {
    trainingEnabled,
    suppliersEnabled,
    changeOrdersEnabled,
    qualityEventsEnabled,
    partsEnabled,
    productsEnabled,
    documentsEnabled,
    sbomEnabled,
    inventoryEnabled,
    complianceEnabled,
  } = useFeature()
  const { open, navWidth } = useNavStore()
  const currentOrgUser = getCurrentOrgUser()
  const { pathname } = useLocation()
  const { orgName } = useParams()
  const { canReadSettings } = useSettingsActions()
  const { canSeeTraining } = useTrainingActions()
  const { canSeeChangeOrders } = useChangeOrderActions()
  const { canSeeQualityEvents } = useQualityEventActions()
  const { canSeeTasks } = useTaskActions()
  const { canSeeCompliance } = useComplianceActions()
  const { canSeeSbom } = useSbomActions()

  const { canSeeAuditTrail } = useAuditActions()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const accountMenuOpen = Boolean(anchorEl)

  const upperNavItems = [
    {
      linkTo: routes.home({ orgName }),
      text: 'Home',
      icon: <IconHome />,
      isSelected: pathname === routes.home({ orgName }),
    },
    ...(productsEnabled
      ? [
          {
            linkTo: routes.products({ orgName }),
            text: 'Products',
            icon: <IconCube />,
            isSelected: pathname.startsWith(routes.products({ orgName })),
          },
        ]
      : []),
    ...(changeOrdersEnabled && canSeeChangeOrders()
      ? [
          {
            linkTo: routes.changeOrders({ orgName }),
            text: 'Changes',
            icon: <IconClockRefresh />,
            isSelected: pathname.startsWith(routes.changeOrders({ orgName })),
          },
        ]
      : []),
    ...(qualityEventsEnabled && canSeeQualityEvents()
      ? [
          {
            linkTo: routes.qualityEvents({ orgName }),
            text: 'Quality Events',
            icon: <IconAward03 />,
            isSelected: pathname.startsWith(routes.qualityEvents({ orgName })),
          },
        ]
      : []),
    ...(documentsEnabled
      ? [
          {
            linkTo: routes.documents({ orgName }),
            text: 'Documents',
            icon: <IconDocuments />,
            isSelected: pathname.startsWith(routes.documents({ orgName })),
          },
        ]
      : []),
    ...(trainingEnabled && canSeeTraining()
      ? [
          {
            linkTo: routes.trainingAssignments({
              orgName,
            }),
            text: 'Training',
            icon: <IconGraduationHat />,
            isSelected: pathname.startsWith(`/${orgName}/training`),
          },
        ]
      : []),
    ...(suppliersEnabled
      ? [
          {
            linkTo: routes.suppliers({
              orgName,
            }),
            text: 'Suppliers',
            icon: <IconTruck />,
            isSelected: pathname.startsWith(routes.suppliers({ orgName })),
          },
        ]
      : []),
    ...(partsEnabled
      ? [
          {
            linkTo: routes.parts({ orgName }),
            text: 'Parts',
            icon: <IconCpuChip />,
            isSelected: pathname.startsWith(routes.parts({ orgName })),
          },
        ]
      : []),
    ...(complianceEnabled && canSeeCompliance()
      ? [
          {
            linkTo: routes.compliance({ orgName }),
            text: 'Compliance',
            icon: <IconAward03 />,
            isSelected: pathname.startsWith(routes.compliance({ orgName })),
          },
        ]
      : []),
    ...(sbomEnabled && canSeeSbom()
      ? [
          {
            linkTo: routes.sboms({ orgName }),
            text: 'SBOM',
            icon: <IconGitPullRequestSVG />,
            isSelected: pathname.startsWith(routes.sboms({ orgName })),
          },
        ]
      : []),
    ...(inventoryEnabled && partsEnabled
      ? [
          {
            linkTo: routes.inventory({ orgName }),
            text: 'Inventory',
            icon: <IconList />,
            isSelected: pathname.startsWith(routes.inventory({ orgName })),
          },
        ]
      : []),
  ]

  const lowerNavItems = [
    ...(canSeeAuditTrail()
      ? [
          {
            linkTo: routes.auditTrail({
              orgName,
            }),
            text: 'Audit Trail',
            icon: <IconFile />,
            isSelected: pathname.startsWith(routes.auditTrail({ orgName })),
          },
        ]
      : []),
    ...(canSeeTasks()
      ? [
          {
            linkTo: routes.tasks({ orgName }),
            text: 'Tasks',
            icon: <IconCheckSquareBroken />,
            isSelected: pathname.startsWith(routes.tasks({ orgName })),
          },
        ]
      : []),
    ...(canReadSettings()
      ? [
          {
            linkTo: routes.userManagement({
              orgName,
            }),
            text: 'Settings',
            icon: <IconSettings />,
            isSelected: false,
          },
        ]
      : []),
  ]

  const drawer = (
    <NavBox>
      <Box sx={{ margin: '20px 24px 13px' }}>
        <Link to={routes.home({ orgName })}>
          <img src={logo} alt="logo" width="28"></img>
        </Link>
      </Box>
      <List sx={{ flex: 1 }}>
        {upperNavItems.map(({ linkTo, text, icon, isSelected }) => (
          <Link to={linkTo} key={text}>
            <Tooltip placement="right" title={open ? null : text}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={isSelected}
                  sx={{ paddingLeft: '28px' }}
                >
                  <ListItemIcon
                    sx={({ palette }) => ({ color: palette.text.primary })}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                    {text}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </Link>
        ))}
      </List>
      <List>
        {lowerNavItems.map(({ linkTo, text, icon, isSelected }) => (
          <Link to={linkTo} key={text}>
            <Tooltip placement="right" title={open ? null : text}>
              <ListItem disablePadding>
                <ListItemButton
                  selected={isSelected}
                  sx={{ paddingLeft: '28px' }}
                >
                  <ListItemIcon
                    sx={({ palette }) => ({ color: palette.text.primary })}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText sx={{ opacity: open ? 1 : 0 }} primary={text} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </Link>
        ))}
      </List>
      <Divider />
      {currentOrgUser && (
        <List disablePadding>
          <ListItemButton
            data-testid="left-nav-account-menu"
            onClick={(event) => {
              setAnchorEl(event.currentTarget)
            }}
          >
            <ListItemIcon>
              <Avatar
                sx={{
                  mr: '10px',
                  backgroundColor: 'primary.main',
                  width: 32,
                  height: 32,
                }}
              >
                {currentOrgUser.firstName[0] + currentOrgUser.lastName[0]}
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={currentOrgUser.firstName + ' ' + currentOrgUser.lastName}
              secondary={currentOrgUser.organization.longName}
              sx={({ palette }) => ({
                '& .MuiListItemText-primary': {
                  color: `${palette.text.primary} !important`,
                },
                opacity: open ? 1 : 0,
              })}
            />
            {accountMenuOpen ? <IconChevronUp /> : <IconChevronDown />}
          </ListItemButton>
          <AccountMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            open={accountMenuOpen}
            currentOrgUser={currentOrgUser}
          />
        </List>
      )}
    </NavBox>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{
          width: { sm: navWidth },
          flexShrink: { sm: 0 },
        }}
      >
        <Drawer
          variant="permanent"
          anchor="left"
          sx={({ palette, typography, transitions }) => ({
            display: { xs: 'none', sm: 'block' },

            '& .MuiDrawer-paper': {
              boxShadow: 'none',
              boxSizing: 'border-box',
              width: navWidth,
              height: '100%',
              top: 0,
              borderRadius: 0,
              transition: transitions.create('width', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.standard,
              }),
              overflowX: 'hidden',
            },

            '& .MuiListItem--root': {
              height: '40px',
            },

            '& .MuiListItemButton-root': {
              marginLeft: '2px',

              '& .MuiListItemIcon-root': {
                minWidth: '30px',
              },

              '&.Mui-selected': {
                marginLeft: 'unset',
                borderLeft: `2px solid ${palette.primary.main}`,
              },
            },

            '& .MuiListItemText-primary': {
              fontSize: typography.fontSize,
            },
          })}
          open
        >
          {drawer}
        </Drawer>
        <DrawerToggleButton />
      </Box>
      <Box
        component="main"
        sx={{
          minHeight: '100vh',
          width: { sm: `calc(100% - ${navWidth}px)` },
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default MainNav
