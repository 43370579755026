export const qualityEventTypesQuery = gql`
  query QualityEventTypesQuery($includeArchived: Boolean) {
    qualityEventTypes(includeArchived: $includeArchived) {
      id
      archivedAt
      prefix {
        text
      }
      latestVersion {
        name
      }
    }
  }
`
