import { ApolloError } from '@apollo/client/errors'
import { Skeleton } from '@mui/material'
import { Role, UserInputErrorType } from 'types/graphql'

import { toast } from '@redwoodjs/web/dist/toast'

import ErrorMessage from 'src/components/ErrorMessage'

export const useEscapeKeydown = (onEscapeKeydown: () => void): void => {
  React.useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onEscapeKeydown()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [onEscapeKeydown])
}

export const getAuthHeaders = (accessToken: string) => {
  return {
    Authorization: `Bearer ${accessToken}`,
    'auth-provider': 'auth0',
    'Content-Type': 'application/json',
  }
}

export const getAuth0RedirectUri = () => {
  const isDeployedEnv =
    !!process.env.COHERENCE_ENVIRONMENT_NAME &&
    ['main', 'production'].includes(process.env.COHERENCE_ENVIRONMENT_NAME)

  const isPullRequestEnv =
    !!process.env.COHERENCE_ENVIRONMENT_NAME &&
    !['main', 'production'].includes(process.env.COHERENCE_ENVIRONMENT_NAME)

  return isDeployedEnv
    ? process.env.AUTH0_REDIRECT_URI
    : isPullRequestEnv
    ? `https://${process.env.COHERENCE_ENVIRONMENT_DOMAIN}`
    : process.env.AUTH0_REDIRECT_URI
}

export const capitalizeFirstLetterAndLowercaseRest = (text: string) => {
  return text.charAt(0)?.toUpperCase() + text.slice(1)?.toLowerCase()
}
export const capitalizeEachWord = (text: string) => {
  return text.split(' ').map(capitalizeFirstLetterAndLowercaseRest).join(' ')
}

export const pluralize = (count: number, singular: string, plural?: string) => {
  return count === 1 ? singular : plural || singular + 's'
}

export const stringToColor = (string: string) => {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

const errorMessageConfig: { [key in UserInputErrorType]: string } = {
  USER_ALREADY_EXISTS_IN_ORGANIZATION: 'User already exists in organization',
  DUPLICATE_RECORD_KEY: 'This ID is already taken',
  PRODUCT_CLONE_ATTEMPT_INVALID_HARM: `Product cannot be cloned while there are invalid harms. Please fix all harms by assigning a severity or deleting the harm, and try cloning again.`,
  DOCUMENT_USED_TO_CREATE_PART_ALREADY_BACKS_ANOTHER_PART: `The document used to create the Part already backs another Part`,
  INVALID_SPDX_FILE: `The file uploaded is not a valid SPDX file, please try again.`,
  LOT_ALREADY_EXISTS: 'This lot number is already taken',
  INVALID_INVENTORY_QUANTITIES: 'Invalid quantities',
  INVALID_PRODUCT_DOCUMENT_TEMPLATE: 'Invalid product document template',
  CANNOT_RESET_DISPLAY_ID_WHILE_DESIGN_ELEMENT_IN_REVIEW:
    'Cannot reset display IDs while design element is in review',
  RISK_SHEET_CURRENTLY_CHECKED_OUT:
    'Can not perform operation. Risk sheet is currently checked out.',
  RISK_SHEET_NOT_CHECKED_OUT:
    'Can not perform operation. Risk sheet is not checked out.',
}

const isUserInputError = (
  error?: ApolloError | Error | string | null | undefined
) => error instanceof ApolloError && error.message in errorMessageConfig

export const getErrorMessage = (
  error?: ApolloError | Error | string | null | undefined
): string => {
  const DEFAULT_MESSAGE =
    'Something went wrong—our team has been alerted. Please refresh the page and try again.'

  if (isUserInputError(error)) {
    return errorMessageConfig[
      (error as ApolloError).message as UserInputErrorType
    ]
  }

  if (process.env.NODE_ENV !== 'development') {
    return DEFAULT_MESSAGE
  }

  if (typeof error === 'string') {
    return error
  }

  if (error?.message) {
    return error.message
  }

  return DEFAULT_MESSAGE
}

export const defaultApolloErrorHandler = (error: ApolloError | Error) => {
  toast.error(getErrorMessage(error))

  if (isUserInputError(error)) {
    return undefined
  }
  throw error
}

export const renderQuery = <
  TData,
  TVariables extends GraphQLOperationVariables
>({
  query,
  loading,
  error,
  success,
}: {
  query: QueryOperationResult<TData, TVariables>
  loading?: JSX.Element | string
  error?: JSX.Element | string
  success: (data: TData) => JSX.Element | string
}): JSX.Element | string => {
  if (query.loading && !query.data) return loading || <Skeleton />

  if (query.error || !query.data)
    return error || <ErrorMessage error={query.error} />

  return success(query.data)
}

export const getRoleString = (role: Role) => {
  switch (role) {
    case 'SUPERADMIN':
      return 'Admin'
    case 'QUALITY':
      return 'Quality'
    case 'PRODUCT':
      return 'Product'
    case 'VIEW_ONLY':
      return 'View Only'
  }
}

export { default as downloadFileFromUrl } from './downloadFileFromUrl'

// workaround for https://github.com/mui/mui-x/issues/8011
export const muiTypeFixProps = {
  nonce: undefined,
  onResize: undefined,
  onResizeCapture: undefined,
}

export const caseInsensitiveSearch = (target: string, search: string) => {
  return target.toLowerCase().includes(search.toLowerCase())
}

export const isApple = /Mac|iPod|iPhone|iPad/.test(navigator.platform)

export const wasNewTabKeyClicked = (event: React.MouseEvent) => {
  return event.metaKey || event.ctrlKey
}

export const richTextFieldIsFilled = (
  value?: string | null
): value is string => {
  if (!value) {
    // form was submitted without touching the rich text field
    return false
  }
  if (value === '') {
    // legacy value - set before rich text field was released
    return false
  }
  if (value === '<p></p>') {
    return false
  }

  return true
}

export const isNonNullable = <T,>(value: T): value is NonNullable<T> => !!value

export const arrayHasDuplicates = (array: Array<string | number>) =>
  new Set(array).size !== array.length

export const PREFIX_PLACEHOLDER = '<PREFIX>'

export const overflowEllipsis = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}

export const formatRecordKey = ({
  prefix,
  displayId,
}: {
  prefix: {
    text: string
    displayIdMinLength: number
  }
  displayId: number
}) => {
  return `${prefix.text}-${String(displayId).padStart(
    prefix.displayIdMinLength,
    '0'
  )}`
}
