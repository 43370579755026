import useFeature from 'src/hooks/useFeature'

import CompliancePaywall from './CompliancePaywall'

type ComplianceLayoutProps = {
  children?: React.ReactNode
}

const ComplianceLayout = ({ children }: ComplianceLayoutProps) => {
  const { complianceEnabled } = useFeature()
  if (!complianceEnabled) {
    return <CompliancePaywall />
  }
  return <>{children}</>
}

export default ComplianceLayout
