import { hydrateRoot, createRoot } from 'react-dom/client'

import App from './App'
/**
 * When `#redwood-app` isn't empty then it's very likely that you're using
 * prerendering. So React attaches event listeners to the existing markup
 * rather than replacing it.
 * https://reactjs.org/docs/react-dom-client.html#hydrateroot
 */
const redwoodAppElement = document.getElementById('redwood-app')
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (redwoodAppElement.children?.length > 0) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  hydrateRoot(redwoodAppElement, <App />)
} else {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const root = createRoot(redwoodAppElement)
  root.render(<App />)
}
