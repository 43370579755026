export default gql`
  query FindTrainingPolicyQuery($id: Int!) {
    trainingPolicy(id: $id) {
      id
      deletedAt
      versions {
        id
        versionMajor
        versionMinor
        name
        userGroups {
          id
          name
        }
        users {
          id
          initials
          fullName
          status
        }
        documents {
          id
          resourceKey
          documentType {
            name
          }
          versions {
            id
            updatedAt
            displayVersion
            status
            title
            description
          }
        }
      }
    }
  }
`
