import useFeature from 'src/hooks/useFeature'

import { handleNotFound } from '../layoutUtils'

type QualityEventLayoutProps = {
  children?: React.ReactNode
}

const QualityEventLayout = ({ children }: QualityEventLayoutProps) => {
  const { qualityEventsEnabled } = useFeature()
  if (!qualityEventsEnabled) return handleNotFound()
  return <>{children}</>
}

export default QualityEventLayout
