import type { SVGProps } from 'react'
const IconClockRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.4523 12.8928C20.1745 15.5027 18.6957 17.9484 16.2487 19.3612C12.1832 21.7084 6.98468 20.3155 4.63747 16.25L4.38747 15.817M3.54519 11.1071C3.82299 8.49723 5.30178 6.05151 7.74877 4.63874C11.8143 2.29153 17.0128 3.68447 19.36 7.74995L19.61 8.18297M3.49219 18.0659L4.22424 15.3339L6.95629 16.0659M17.0412 7.93398L19.7733 8.66603L20.5053 5.93398M11.9988 7.49995V12L14.4988 13.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default IconClockRefresh
