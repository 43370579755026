import { Card, CardContent, Stack, Typography, useTheme } from '@mui/material'

import Header from 'src/components/Header/Header'

import OneColumnLayout from '../OneColumnLayout/OneColumnLayout'

import frameworkMetrics from './screenshots/frameworkMetrics.png'
import requirementEvidence from './screenshots/requirementEvidence.png'
import requirementMetrics from './screenshots/requirementMetrics.png'

export default () => {
  const { palette } = useTheme()
  return (
    <>
      <Header title="Compliance" />
      <OneColumnLayout>
        <Card>
          <CardContent>
            <Stack gap={1.5}>
              <Typography variant="h3">
                Are you having trouble keeping track of all the standards and
                regulations that your organization and product need to align
                with?
              </Typography>

              <Typography>
                Introducing the new Compliance module: your supercharged
                regulatory checklist, customizable to your needs and deeply
                integrated with the rest of your Ultralight QMS. Maintain clear
                visibility of any changes your team is making that may impact
                your regulatory compliance, so you can communicate and resolve
                issues before they force large and expensive rework to your
                product.
              </Typography>
            </Stack>
          </CardContent>
        </Card>

        <Stack alignItems="center" mt={6}>
          <Stack width="720px" gap={6}>
            <Stack gap={1.5}>
              <Typography variant="h2">Completion Overviews</Typography>
              <Typography>
                Easily monitor the status of frameworks to stay on top of your
                organization&apos;s regulatory compliance.
              </Typography>
              <img
                src={frameworkMetrics}
                alt="Framework Metrics"
                style={{
                  border: `2px solid ${palette.grey.border}`,
                  borderRadius: '8px',
                }}
              />
              <img
                src={requirementMetrics}
                alt="Requirement Metrics"
                style={{
                  border: `2px solid ${palette.grey.border}`,
                  borderRadius: '8px',
                }}
              />
            </Stack>

            <Stack gap={1.5}>
              <Typography variant="h2">Shared Requirements</Typography>
              <Typography>
                De-duplicate effort. Update details and evidence on requirement,
                and have them reflects across all your frameworks.
              </Typography>
            </Stack>

            <Stack gap={1.5}>
              <Typography variant="h2">Real-time Evidence Status</Typography>
              <Typography>
                Stay informed by tracking changes your team is making to linked
                documents and design elements.
              </Typography>
              <img
                src={requirementEvidence}
                alt="Requirement Evidence"
                style={{
                  width: '400px',
                  border: `2px solid ${palette.grey.border}`,
                  borderRadius: '8px',
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </OneColumnLayout>
    </>
  )
}
