import {
  DocumentPermissions,
  DocumentPermissionsQuery,
  DocumentPermissionsQueryVariables,
  DocumentVersionStatus,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

const QUERY = gql`
  query DocumentPermissionsQuery {
    permissions {
      document {
        readEffective
        readNonEffective
        write
        createOrModifyDocumentTypes
      }
    }
  }
`

const useDocumentPermissions = () => {
  const { loading, data } = useQuery<
    DocumentPermissionsQuery,
    DocumentPermissionsQueryVariables
  >(QUERY)

  return {
    loading,
    documentPermissions: data?.permissions.document ?? null,
  }
}

export const useDocumentActions = () => {
  const { documentPermissions } = useDocumentPermissions()
  return {
    canViewDocument: (
      deletedFingerprint: number,
      status: DocumentVersionStatus,
      isMigrationArchive: boolean,
      isApproverOrReviewer: boolean
    ) =>
      canViewDocument(
        deletedFingerprint,
        status,
        isMigrationArchive,
        isApproverOrReviewer,
        documentPermissions
      ),
    canViewNonEffective: (isApproverOrReviewer: boolean) =>
      canViewDocument(
        0,
        'DRAFT',
        false,
        isApproverOrReviewer,
        documentPermissions
      ),
    canEditDocument: (
      deletedFingerprint: number,
      status: DocumentVersionStatus,
      isLatestVersion: boolean,
      isInOpenChangeOrder: boolean
    ) =>
      canEditDocument(
        deletedFingerprint,
        status,
        isLatestVersion,
        isInOpenChangeOrder,
        documentPermissions
      ),
    canSendDocumentForReview: (
      deletedFingerprint: number,
      status: DocumentVersionStatus,
      isLatestVersion: boolean,
      isInOpenChangeOrder: boolean
    ) =>
      canSendDocumentForReview(
        deletedFingerprint,
        status,
        isLatestVersion,
        isInOpenChangeOrder,
        documentPermissions
      ),
    canSendDocumentForApproval: (
      deletedFingerprint: number,
      status: DocumentVersionStatus,
      isLatestVersion: boolean,
      isInOpenChangeOrder: boolean
    ) =>
      canSendDocumentForApproval(
        deletedFingerprint,
        status,
        isLatestVersion,
        isInOpenChangeOrder,
        documentPermissions
      ),
    canObsoleteDocument: (
      deletedFingerprint: number,
      status: DocumentVersionStatus,
      isLatestVersion: boolean,
      isInOpenChangeOrder: boolean
    ) =>
      canObsoleteDocument(
        deletedFingerprint,
        status,
        isLatestVersion,
        isInOpenChangeOrder,
        documentPermissions
      ),
    canDiscardDocument: (
      deletedFingerprint: number,
      status: DocumentVersionStatus,
      isLatestVersion: boolean,
      isInOpenChangeOrder: boolean
    ) =>
      canDiscardDocument(
        deletedFingerprint,
        status,
        isLatestVersion,
        isInOpenChangeOrder,
        documentPermissions
      ),
    canExportDocument: (
      deletedFingerprint: number,
      status: DocumentVersionStatus
    ) => canExportDocument(deletedFingerprint, status, documentPermissions),
    canWithdrawReviewRequests: (
      deletedFingerprint: number,
      status: DocumentVersionStatus,
      isInReviewViaChangeOrder: boolean
    ) =>
      canWithdrawReviewRequests(
        deletedFingerprint,
        status,
        isInReviewViaChangeOrder,
        documentPermissions
      ),
    canWithdrawApprovalRequests: (
      deletedFingerprint: number,
      status: DocumentVersionStatus,
      isInApprovalViaChangeOrder: boolean
    ) =>
      canWithdrawApprovalRequests(
        deletedFingerprint,
        status,
        isInApprovalViaChangeOrder,
        documentPermissions
      ),
    canWithdrawObsoletionRequests: (
      deletedFingerprint: number,
      status: DocumentVersionStatus
    ) =>
      canWithdrawObsoletionRequests(
        deletedFingerprint,
        status,
        documentPermissions
      ),
    canCreateDocument: () => canCreateDocument(documentPermissions),
    canCommentOnDocuments: () => canCommentOnDocuments(documentPermissions),
    canEditDocumentReminders: () =>
      canEditDocumentReminders(documentPermissions),
    canEditDocumentVersionTags: (
      deletedFingerprint: number,
      isLatestVersion: boolean
    ) =>
      canEditDocumentVersionTags(
        deletedFingerprint,
        isLatestVersion,
        documentPermissions
      ),
    canRestoreDocumentVersion: (deletedFingerprint: number) =>
      canRestoreDocumentVersion(deletedFingerprint, documentPermissions),
    canApproveDocuments: () => canApproveDocuments(documentPermissions),
    canModifyDocumentIndices: () =>
      canModifyDocumentIndices(documentPermissions),
    cancreateOrModifyDocumentTypes: () =>
      cancreateOrModifyDocumentTypes(documentPermissions),
    canDownloadOriginalDocument: () =>
      canDownloadOriginalDocument(documentPermissions),
    canSeeDocumentMetrics: () => canSeeDocumentMetrics(documentPermissions),
  }
}

type MaybeDocumentPermissions = DocumentPermissions | null

const canEditDocumentReminders = (
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (!documentPermissions?.write) {
    return false
  }
  return true
}

const canEditDocumentVersionTags = (
  deletedFingerprint: number,
  isLatestVersion: boolean,
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (deletedFingerprint) {
    return false
  }
  if (!documentPermissions?.write) {
    return false
  }
  return isLatestVersion
}

const canViewDocument = (
  deletedFingerprint: number,
  status: DocumentVersionStatus,
  isMigrationArchive: boolean,
  isApproverOrReviewer: boolean,
  documentPermissions: MaybeDocumentPermissions
): boolean | null => {
  if (documentPermissions === null) {
    return null
  }

  if (deletedFingerprint) {
    return false
  }

  if (
    (status === 'EFFECTIVE' && documentPermissions?.readEffective) ||
    (status !== 'EFFECTIVE' &&
      isApproverOrReviewer &&
      documentPermissions?.readEffective)
  ) {
    return true
  }

  if (status !== 'EFFECTIVE' && documentPermissions?.readNonEffective) {
    return true
  }

  if (isMigrationArchive && documentPermissions?.readNonEffective) {
    return true
  }

  return false
}

const canEditDocument = (
  deletedFingerprint: number,
  status: DocumentVersionStatus,
  isLatestVersion: boolean,
  isInOpenChangeOrder: boolean,
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (deletedFingerprint) {
    return false
  }
  if (
    documentPermissions?.write &&
    isLatestVersion &&
    (status === 'DRAFT' || status === 'IN_REVIEW')
  ) {
    return true
  }

  if (
    documentPermissions?.write &&
    isLatestVersion &&
    status === 'EFFECTIVE' &&
    !isInOpenChangeOrder
  ) {
    return true
  }

  return false
}

const canSendDocumentForReview = (
  deletedFingerprint: number,
  status: DocumentVersionStatus,
  isLatestVersion: boolean,
  isInOpenChangeOrder: boolean,
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (deletedFingerprint) {
    return false
  }
  if (isInOpenChangeOrder) {
    return false
  }
  if (documentPermissions?.write && status === 'DRAFT' && isLatestVersion) {
    return true
  }
  return false
}

const canSendDocumentForApproval = (
  deletedFingerprint: number,
  status: DocumentVersionStatus,
  isLatestVersion: boolean,
  isInOpenChangeOrder: boolean,
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (deletedFingerprint) {
    return false
  }
  if (isInOpenChangeOrder) {
    return false
  }
  if (documentPermissions?.write && status === 'DRAFT' && isLatestVersion) {
    return true
  }
  return false
}

const canObsoleteDocument = (
  deletedFingerprint: number,
  status: DocumentVersionStatus,
  isLatestVersion: boolean,
  isInOpenChangeOrder: boolean,
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (deletedFingerprint) {
    return false
  }
  if (
    documentPermissions?.write &&
    status === 'EFFECTIVE' &&
    isLatestVersion &&
    !isInOpenChangeOrder
  ) {
    return true
  }
  return false
}

const canDiscardDocument = (
  deletedFingerprint: number,
  status: DocumentVersionStatus,
  isLatestVersion: boolean,
  isInOpenChangeOrder: boolean,
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (deletedFingerprint) {
    return false
  }
  if (isInOpenChangeOrder) {
    return false
  }
  if (!documentPermissions?.write) {
    return false
  }
  return status === 'DRAFT' && isLatestVersion
}

const canExportDocument = (
  deletedFingerprint: number,
  status: DocumentVersionStatus,
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (deletedFingerprint) {
    return false
  }
  if (documentPermissions?.readEffective && status === 'EFFECTIVE') {
    return true
  }
  if (documentPermissions?.readNonEffective && status === 'OBSOLETE') {
    return true
  }
  return false
}

const canWithdrawReviewRequests = (
  deletedFingerprint: number,
  status: DocumentVersionStatus,
  isInReviewViaChangeOrder: boolean,
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (deletedFingerprint) {
    return false
  }
  if (!documentPermissions?.write) {
    return false
  }
  if (isInReviewViaChangeOrder) {
    return false
  }
  return status === 'IN_REVIEW'
}

const canApproveDocuments = (
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (!documentPermissions?.write) {
    return false
  }
  return true
}

const canWithdrawApprovalRequests = (
  deletedFingerprint: number,
  status: DocumentVersionStatus,
  isInApprovalViaChangeOrder: boolean,
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (deletedFingerprint) {
    return false
  }
  if (!documentPermissions?.write) {
    return false
  }
  if (isInApprovalViaChangeOrder) {
    return false
  }
  return status === 'IN_APPROVAL'
}

const canWithdrawObsoletionRequests = (
  deletedFingerprint: number,
  status: DocumentVersionStatus,
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (deletedFingerprint) {
    return false
  }
  if (!documentPermissions?.write) {
    return false
  }
  return status === 'OBSOLETION_REQUESTED'
}

const canCreateDocument = (
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (!documentPermissions?.write) {
    return false
  }
  return true
}

const canCommentOnDocuments = (
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (!documentPermissions?.write) {
    return false
  }
  return true
}

const canRestoreDocumentVersion = (
  deletedFingerprint: number,
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (!documentPermissions?.write) {
    return false
  }
  return deletedFingerprint !== 0
}

const canDownloadOriginalDocument = (
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (!documentPermissions?.readNonEffective) {
    return false
  }
  return true
}

const canModifyDocumentIndices = (
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (!documentPermissions?.write) {
    return false
  }
  return true
}

const cancreateOrModifyDocumentTypes = (
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (!documentPermissions?.createOrModifyDocumentTypes) {
    return false
  }
  return true
}

const canSeeDocumentMetrics = (
  documentPermissions: MaybeDocumentPermissions
): boolean => {
  if (!documentPermissions?.readNonEffective) {
    return false
  }
  return true
}
