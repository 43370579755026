import useFeature from 'src/hooks/useFeature'

import { handleNotFound } from '../layoutUtils'

type SbomLayoutProps = {
  children?: React.ReactNode
}

const SbomLayout = ({ children }: SbomLayoutProps) => {
  const { sbomEnabled } = useFeature()
  if (!sbomEnabled) {
    return handleNotFound()
  }

  return <>{children}</>
}

export default SbomLayout
