import { Box, CircularProgress } from '@mui/material'

import { useProductStore } from 'src/stores'

type ProductLayoutProps = {
  children?: React.ReactNode
}

const ProductLayout = ({ children }: ProductLayoutProps) => {
  const { products } = useProductStore()

  // Avoid rendering children when product is not loaded yet
  if (Object.keys(products ?? {}).length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(100vh - 56px - 48px)',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return <>{children}</>
}

export default ProductLayout
