import {
  Box,
  Breadcrumbs,
  Divider,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import ReactDOM from 'react-dom'

import { Link, back } from '@redwoodjs/router'

import useNavStore from 'src/stores/useNavStore'

import BackLink from '../Backlink/Backlink'

export type HeaderBreadcrumbs = {
  backLink: string | 'BROWSER_BACK'
  labels: string[] | React.ReactNode[]
}

export const HEADER_HEIGHT = 56

const Header: React.FC<{
  sticky?: boolean
  title?: string
  breadcrumbs?: HeaderBreadcrumbs
  children?: React.ReactNode | React.ReactNode[]
  navigateAwaySideEffects?: (e: React.MouseEvent) => void
}> = ({
  sticky,
  title,
  breadcrumbs,
  children,
  navigateAwaySideEffects = () => {},
}) => {
  const { navWidth } = useNavStore()

  return (
    <Box sx={{ height: `${HEADER_HEIGHT}px` }}>
      <Paper
        elevation={0}
        sx={({ palette, zIndex }) => ({
          borderRadius: 0,
          borderBottom: `1px solid ${palette.grey.border}`,
          height: `${HEADER_HEIGHT}px`,
          padding: '0 24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ...(sticky
            ? {
                position: 'fixed',
                width: `calc(100% - ${navWidth}px)`,
                zIndex: zIndex.appBar,
              }
            : {}),
        })}
      >
        {title && (
          <Typography variant="h2" data-testid="main-header-title">
            {title}
          </Typography>
        )}

        {breadcrumbs && (
          <Stack
            spacing={2}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            sx={{
              alignItems: 'center',
            }}
          >
            {breadcrumbs.backLink === 'BROWSER_BACK' ? (
              <Box onClick={back}>
                <BackLink />
              </Box>
            ) : (
              <Link to={breadcrumbs.backLink} onClick={navigateAwaySideEffects}>
                <BackLink />
              </Link>
            )}

            {/*
            Consider integrating Breadcrumbs with the redwood router, to make our
            breadcrumbs more declarative (as opposed to specifying the labels)
            https://mui.com/material-ui/react-breadcrumbs/#integration-with-react-router

            We may want to implement if we wnat to make the breadcrumb elements linkable,
            and/or they start getting deeply nested on some pages.
            */}
            <Breadcrumbs data-testid="main-header-breadcrumbs">
              {breadcrumbs.labels.map((label, index) => (
                <Box key={index}>{label}</Box>
              ))}
            </Breadcrumbs>
          </Stack>
        )}

        <Stack direction="row" spacing="12px" alignItems="center">
          {children || <div id="header-actions-portal"></div>}
        </Stack>
      </Paper>
    </Box>
  )
}

export const HeaderActionsPortal = ({
  portalId = 'header-actions-portal',
  actions,
}: {
  portalId?: string
  actions: React.ReactNode
}) => {
  const portal = window.document.getElementById(portalId)

  return <>{portal && ReactDOM.createPortal(actions, portal)}</>
}

export default Header
