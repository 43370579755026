import { useMediaQuery } from '@mui/material'

import uiStorage from 'src/lib/localStorage/uiStorage'

export const useDarkMode = () => {
  const systemDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const localStorageDarkMode = uiStorage.darkMode.get()

  const [darkMode, setDarkMode] = React.useState(false)
  const prefersDarkMode = localStorageDarkMode ?? systemDarkMode
  React.useEffect(() => {
    setDarkMode(prefersDarkMode)
  }, [prefersDarkMode])

  const darkModeToggler = React.useMemo(
    () => ({
      enable: () => {
        uiStorage.darkMode.set(true)
        setDarkMode(true)
      },
      disable: () => {
        uiStorage.darkMode.set(false)
        setDarkMode(false)
      },
    }),
    []
  )

  return {
    darkMode,
    darkModeToggler,
  }
}

const DarkModeContext = React.createContext({
  enable: () => {},
  disable: () => {},
})

export default DarkModeContext
