import { create } from 'zustand'

import uiStorage from 'src/lib/localStorage/uiStorage'

interface NavStore {
  open: boolean
  navWidth: number
  handleDrawerToggle: (open: boolean) => void
}

const localStorageOpen = uiStorage.mainNav.getExpanded()

const MAIN_NAV_WIDTH = 240
const MINIMIZED_MAIN_NAV_WIDTH = 72

const useNavStore = create<NavStore>()((set) => ({
  open: localStorageOpen,
  navWidth: localStorageOpen ? MAIN_NAV_WIDTH : MINIMIZED_MAIN_NAV_WIDTH,

  handleDrawerToggle: (open: boolean) => {
    set({ open, navWidth: open ? MAIN_NAV_WIDTH : MINIMIZED_MAIN_NAV_WIDTH })
    uiStorage.mainNav.setExpanded(open)
  },
}))

export default useNavStore
