import type {
  SbomPermissions,
  SbomPermissionsQuery,
  SbomPermissionsQueryVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import uiStorage from 'src/lib/localStorage/uiStorage'

const QUERY = gql`
  query SbomPermissionsQuery {
    permissions {
      sbom {
        readSbom
        writeSbom
      }
    }
  }
`

const useSbomPermissions = () => {
  const { loading, data } = useQuery<
    SbomPermissionsQuery,
    SbomPermissionsQueryVariables
  >(QUERY)

  React.useEffect(() => {
    if (data) {
      uiStorage.cachedPermissions.set(data.permissions)
    }
  }, [data])

  const cachedPermissions = uiStorage.cachedPermissions.get()

  return {
    loading,
    sbomPermissions: cachedPermissions?.sbom ?? null,
  }
}

type MaybeSbomPermissions = SbomPermissions | null

export const useSbomActions = () => {
  const { sbomPermissions } = useSbomPermissions()
  return {
    canSeeSbom: () => canSeeSbom(sbomPermissions),
  }
}

const canSeeSbom = (sbomPermissions: MaybeSbomPermissions): boolean => {
  if (!sbomPermissions?.readSbom) {
    return false
  }
  return true
}
