import _ from 'lodash'
import { LatestProduct } from 'types/graphql'
import { create } from 'zustand'

interface ProductState {
  products: Record<number, LatestProduct>
  productOrder: number[]
  error: string | null
  loading: boolean
  setError: (error: string | null) => void
  setLoading: (loading: boolean) => void
  setProducts: (products: LatestProduct[]) => void
}

const useProductStore = create<ProductState>()((set) => ({
  products: {},
  productOrder: [],
  error: null,
  loading: false,
  setError: (error: string | null) => set({ error }),
  setLoading: (loading: boolean) => set({ loading }),
  setProducts: (products: LatestProduct[]) => {
    return set({
      products: _.keyBy(products, (p) => p.rootId),
      productOrder: _.map(products, (p) => p.rootId),
    })
  },
}))

export default useProductStore
