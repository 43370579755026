import {
  ApproveDesignReviewMutation,
  ApproveDesignReviewMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { defaultApolloErrorHandler } from 'src/utils'

const APPROVE_DESIGN_REVIEW = gql`
  mutation ApproveDesignReviewMutation($input: DesignReviewApprovalInput!) {
    approveDesignReview(input: $input)
  }
`

const useApproveDesignReviewMutation = ({
  options,
}: {
  options?: GraphQLMutationHookOptions<
    ApproveDesignReviewMutation,
    ApproveDesignReviewMutationVariables
  >
} = {}) => {
  const [approveDesignReview, approveDesignReviewMutationResult] = useMutation<
    ApproveDesignReviewMutation,
    ApproveDesignReviewMutationVariables
  >(APPROVE_DESIGN_REVIEW, {
    onError: defaultApolloErrorHandler,
    ...options,
  })

  return { approveDesignReview, approveDesignReviewMutationResult }
}

export default useApproveDesignReviewMutation
