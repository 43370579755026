import { useCallback, useMemo } from 'react'

import _ from 'lodash'

import { navigate, useLocation } from '@redwoodjs/router'

type ParamKey = 'type' | 'selectedIds' | 'versionId'

/**
 * Limiting URL param keys to `type` and `selectedId`
 */
class ModalSearchParams extends URLSearchParams {
  get(name: ParamKey): string | null {
    return super.get(name)
  }

  set(name: ParamKey, value: string) {
    super.set(name, value)
  }

  delete(name: ParamKey): void {
    super.delete(name)
  }
}

class SearchParams {
  readonly searchParams
  constructor(search?: string) {
    this.searchParams = new ModalSearchParams(search)
  }

  get type(): string | null {
    return this.searchParams.get('type')
  }

  set type(type: string | null) {
    if (type) {
      this.searchParams.set('type', type)
    } else {
      this.searchParams.delete('type')
    }
  }

  get selectedIds(): number[] | null {
    const str = this.searchParams.get('selectedIds')
    if (!str) {
      return null
    }
    const numStrings = str.split(',')
    try {
      return numStrings.map((numStr) => {
        const num = Number.parseInt(numStr)
        if (isNaN(num)) {
          throw Error('design element ID must be a number')
        }
        return num
      })
    } catch {
      // do nothing
    }
    return null
  }

  set selectedIds(ids: number[] | null) {
    if (ids !== null) {
      this.searchParams.set('selectedIds', ids.map((id) => `${id}`).join(','))
    } else {
      this.searchParams.delete('selectedIds')
    }
  }

  get versionId(): number | null {
    const str = this.searchParams.get('versionId')
    if (!str) {
      return null
    }
    const num = Number.parseInt(str)
    if (isNaN(num)) {
      return null
    }
    return num
  }

  set versionId(id: number | null) {
    if (id !== null) {
      this.searchParams.set('versionId', `${id}`)
    } else {
      this.searchParams.delete('versionId')
    }
  }

  clear(): void {
    this.searchParams.delete('versionId')
    this.searchParams.delete('selectedIds')
    this.searchParams.delete('type')
  }

  toString(): string {
    return this.searchParams.toString()
  }
}

export type DesignElementTypeQueryParam =
  | 'un'
  | 'sr'
  | 'ssr'
  | 'do'
  | 'ver'
  | 'val'

export const useDesignElementSearchParams = () => {
  const { pathname, search } = useLocation()

  const searchParams = useMemo(() => {
    return new SearchParams(search)
  }, [search])

  const paramsNavigate = useCallback(
    () => navigate(`${pathname}?${searchParams}`),
    [pathname, searchParams]
  )

  return {
    params: {
      type: searchParams.type,
      selectedIds: searchParams.selectedIds,
      versionId: searchParams.versionId,
    },
    set: ({
      type,
      selectedIds,
      versionId,
    }: {
      type?: DesignElementTypeQueryParam | null
      selectedIds?: number[] | null
      versionId?: number | null
    }) => {
      let dirty = false
      if (type !== undefined) {
        if (!dirty) {
          dirty = searchParams.type !== type
        }
        searchParams.type = type
      }
      if (selectedIds !== undefined) {
        if (!dirty) {
          dirty = !_.isEqual(
            searchParams.selectedIds?.sort(),
            selectedIds?.sort()
          )
        }
        searchParams.selectedIds = selectedIds
      }
      if (versionId !== undefined) {
        if (!dirty) {
          dirty = searchParams.versionId !== versionId
        }
        searchParams.versionId = versionId
      }
      if (dirty) {
        paramsNavigate()
      }
    },
    clear: () => {
      searchParams.clear()
      paramsNavigate()
    },
  }
}
