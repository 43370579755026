export const riskProbabilitiesByMatrixIdQuery = gql`
  query RiskProbabilitiesByMatrixIdQuery($matrixId: Int!) {
    riskProbabilities: riskProbabilitiesByMatrixId(matrixId: $matrixId) {
      id
      index
      denominator
      value
      title
      description
    }
  }
`
