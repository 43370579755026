import {
  AuditPermissionsQuery,
  ChangeOrderPermissionsQuery,
  CompliancePermissionsQuery,
  QualityEventPermissionsQuery,
  SbomPermissionsQuery,
  SettingsPermissionsQuery,
  TaskPermissionsQuery,
  TrainingPermissionsQuery,
  UsersQuery,
} from 'types/graphql'

import logger from '../logger'

interface CachedPermissions {
  training?: TrainingPermissionsQuery['permissions']['training']
  changeOrder?: ChangeOrderPermissionsQuery['permissions']['changeOrder']
  qualityEvent?: QualityEventPermissionsQuery['permissions']['qualityEvent']
  task?: TaskPermissionsQuery['permissions']['task']
  audit?: AuditPermissionsQuery['permissions']['audit']
  settings?: SettingsPermissionsQuery['permissions']['settings']
  compliance?: CompliancePermissionsQuery['permissions']['compliance']
  sbom?: SbomPermissionsQuery['permissions']['sbom']
}

const uiStorage = {
  mainNav: {
    getExpanded: () =>
      window.localStorage.getItem('mainNavExpanded') !== 'false',
    setExpanded: (expanded: boolean) =>
      window.localStorage.setItem('mainNavExpanded', String(expanded)),

    getCachedPermissions: (): CachedPermissions | null =>
      JSON.parse(window.localStorage.getItem('cachedPermissions')),
    setCachedPermissions: (permissions: CachedPermissions) =>
      window.localStorage.setItem(
        'cachedPermissions',
        JSON.stringify(permissions)
      ),
  },
  sidePanel: {
    getWidth: () =>
      Number(window.localStorage.getItem('sidePanelWidth')) || 320,
    setWidth: (width: number) =>
      window.localStorage.setItem('sidePanelWidth', String(width)),
  },
  cachedPermissions: {
    get: (): CachedPermissions | null => {
      let cachedPermissions
      try {
        cachedPermissions = JSON.parse(
          window.localStorage.getItem('cachedPermissions')
        )
      } catch (err) {
        logger.error(err)
        return null
      }
      return cachedPermissions
    },
    set: (permissions: CachedPermissions) => {
      const prev = uiStorage.cachedPermissions.get()
      const next = {
        ...prev,
        ...permissions,
      }

      window.localStorage.setItem('cachedPermissions', JSON.stringify(next))
    },
  },
  darkMode: {
    get: (): boolean | null => {
      const darkMode = window.localStorage.getItem('darkMode')
      return darkMode === null ? null : darkMode === 'true'
    },
    set: (darkMode: boolean) => {
      window.localStorage.setItem('darkMode', String(darkMode))
    },
  },

  cachedOrganizationUsers: {
    get: (): UsersQuery['users'] => {
      let cachedUsers
      try {
        cachedUsers = JSON.parse(
          window.localStorage.getItem('cachedOrganizationUsers')
        )
      } catch (err) {
        logger.error(err)
        return []
      }
      return cachedUsers
    },
    set: (users: UsersQuery['users']) => {
      window.localStorage.setItem(
        'cachedOrganizationUsers',
        JSON.stringify(users)
      )
    },
  },
}

export default uiStorage
