import type { SVGProps } from 'react'
const IconAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={17}
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0001 6.49995V9.83328M10.0001 13.1666H10.0085M8.84622 2.24305L1.99215 14.0819C1.61198 14.7386 1.42189 15.0669 1.44999 15.3364C1.47449 15.5714 1.59763 15.785 1.78876 15.924C2.0079 16.0833 2.38728 16.0833 3.14605 16.0833H16.8542C17.613 16.0833 17.9923 16.0833 18.2115 15.924C18.4026 15.785 18.5258 15.5714 18.5503 15.3364C18.5783 15.0669 18.3883 14.7386 18.0081 14.0819L11.154 2.24305C10.7752 1.58875 10.5858 1.26159 10.3387 1.15172C10.1232 1.05587 9.87709 1.05587 9.66154 1.15172C9.41443 1.26159 9.22503 1.58875 8.84622 2.24305Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default IconAlert
