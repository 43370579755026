import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserGroupsQuery } from 'types/graphql'

type UserGroup = UserGroupsQuery['userGroups'][number]

interface UserGroupsState {
  selectedUserGroup: UserGroup | null
  sidePanelOpen: boolean
}

const initialState: UserGroupsState = {
  selectedUserGroup: null,
  sidePanelOpen: false,
}

export const userGroupsSlice = createSlice({
  name: 'training',
  initialState,
  reducers: {
    selectUserGroup: (state, action: PayloadAction<UserGroup>) => {
      state.selectedUserGroup = action.payload
    },
    clickEmptyArea: (state) => {
      state.selectedUserGroup = null
      state.sidePanelOpen = false
    },
    startCreate: (state) => {
      state.selectedUserGroup = null
      state.sidePanelOpen = true
    },
  },
})

export const { selectUserGroup, clickEmptyArea, startCreate } =
  userGroupsSlice.actions

export default userGroupsSlice.reducer
