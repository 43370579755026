import { Redirect, useParams } from '@redwoodjs/router'

import { useAuth } from 'src/auth'

type AdminContextLayoutProps = {
  children?: React.ReactNode
}

const AdminContextLayout = ({ children }: AdminContextLayoutProps) => {
  const { loading: loadingAuth, currentUser } = useAuth()
  const { orgName } = useParams()
  if (
    orgName !== 'ultralight-admin' ||
    loadingAuth ||
    !currentUser?.organizationUsers.some(
      (orgUser) => orgUser.organization.name === 'ultralight-admin'
    )
  ) {
    return <Redirect to="/404" />
  }

  return <>{children}</>
}

export default AdminContextLayout
