import { useParams } from '@redwoodjs/router'

import { useAuth } from 'src/auth'

export const getCurrentOrgUser = () => {
  const { currentUser, loading } = useAuth()
  const { orgName } = useParams()
  if (loading) {
    return null
  }
  if (currentUser == null || currentUser.organizationUsers.length === 0) {
    throw new Error(`Could not find org specific user`)
  }
  const currentOrgUsers = currentUser.organizationUsers.filter(
    (orgUser) => orgUser.organization.name === orgName
  )
  if (currentOrgUsers.length === 0) {
    throw new Error('User not authorized for the requested organization')
  }
  if (currentOrgUsers.length > 1) {
    throw new Error(
      `${currentOrgUsers.length} orgUsers found for authUser ${currentUser.authUserId_DO_NOT_USE_UNLESS_YOU_KNOW_WHAT_YOURE_DOING}`
    )
  }
  return {
    id: currentOrgUsers[0].id,
    firstName: currentOrgUsers[0].firstName,
    lastName: currentOrgUsers[0].lastName,
    email: currentUser.email,
    role: currentOrgUsers[0].role,
    organization: currentOrgUsers[0].organization,
  }
}
